import { CarouselProps } from 'components/common/Carousel/Carousel';
import { CarouselButtons } from 'components/common/Carousel/CarouselButtons';
import { CarouselCurrentLogo } from 'components/common/Carousel/CarouselCurrentLogo';
import { CarouselLogos } from 'components/common/Carousel/CarouselLogos';
import { useCarouselContext } from 'components/common/Carousel/CarouselProvider';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';

export const CarouselContent = memo(({ items, children }: CarouselProps) => {
  const { currentIndex } = useCarouselContext();
  return (
    <PanelFlex fullWidth column justify="center" align="center" paddingY="LG" as="section">
      <CarouselCurrentLogo items={items} />
      {typeof children === 'function' ? children(items[currentIndex]) : children}
      <CarouselButtons items={items} />
      <CarouselLogos items={items} />
    </PanelFlex>
  );
});

CarouselContent.displayName = 'CarouselContent';
