'use client';
import { useClientSideEffect } from 'hooks/useClientSideEffect';
import { RefObject, useLayoutEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { autoCompleteAtom, useSetAutoCompleteOpen } from 'recoil/autoComplete';

/**
 * Hook for focusing the SearchInput element and closing on click away
 * @params searchInputRef RefObject<HTMLInputElement>
 * @returns useLayoutEffect for controlling focus state
 */

export const usePublicSearchInputFocus = (searchInputRef: RefObject<HTMLInputElement>) => {
  const { open: searchOpen, visible: searchVisible } = useRecoilValue(
    autoCompleteAtom('publicSearch')
  );
  const setSearchOpen = useSetAutoCompleteOpen('publicSearch');

  useLayoutEffect(() => {
    if (
      searchInputRef &&
      searchInputRef.current &&
      (searchOpen || searchVisible) &&
      document.activeElement !== searchInputRef.current
    ) {
      return searchInputRef.current.focus();
    }
  }, [searchOpen, searchInputRef]);

  const closeSearch = () => {
    let parent = document.activeElement.parentElement;
    let isMenuContext = false;

    /* 
      Searches through all parent elements until find the search-input 
      if the active element is not a children from the search input then close the search menu.
    */

    while (parent && !isMenuContext) {
      parent = parent.parentElement;
      if (parent?.id === 'search-input') isMenuContext = true;
    }

    if (!isMenuContext) setSearchOpen(false);
  };

  useClientSideEffect(() => {
    window.addEventListener('focusin', closeSearch);

    return () => {
      window.removeEventListener('focusin', closeSearch);
    };
  });
};
