'use client';
import { ContentfulUiComponentItem } from '@truckmap/common';
import { Button, ButtonOwnProps } from 'components/common/Button';
import { useScrollLink } from 'hooks/useUI/useScrollLink';
import { memo } from 'react';
import { SerializedComponentItem } from 'types/contentful';

type ContentfulButtonProps = {
  button: SerializedComponentItem | ContentfulUiComponentItem;
} & ClickEventProp &
  ButtonOwnProps;

export const ContentfulButton = memo(
  ({ button, onClick, ...buttonProps }: ContentfulButtonProps) => {
    if (!button) return null;

    const { isScrollLink, handleClick: handleClickScrollLink } = useScrollLink(
      button?.primaryLink?.url
    );

    return (
      <Button
        as={button?.primaryLink && !isScrollLink ? 'a' : 'button'}
        href={button?.primaryLink && !isScrollLink ? button?.primaryLink.url : undefined}
        isPrimary={button?.variant === 'primary'}
        dark={button?.variant === 'dark'}
        onClick={isScrollLink ? handleClickScrollLink : onClick}
        {...buttonProps}>
        {button?.primaryText}
      </Button>
    );
  }
);

ContentfulButton.displayName = 'ContentfulButton';
