import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { FullWidthProp } from 'types/common';

export type DropdownSelectOption = {
  value: string;
  label?: string;
};

export type DropdownSelectProps = {
  onChange?: (option: DropdownSelectOption) => void;
  current?: DropdownSelectOption;
  defaultLabel?: string;
  options: DropdownSelectOption[];
} & FullWidthProp;

const useStyles = makeStyles<Omit<DropdownSelectProps, 'options'>>()({
  root: (props) => ['relative', 'cursor-pointer', { 'w-full': props.fullWidth }, props.className],
  select: [
    'border',
    'rounded-md',
    'border-primaryBorder',
    'hover:border-primaryBorderHover',
    'bg-white',
    'w-full',
    'filter shadow-sm hover:shadow',
    'h-[40px]',
    'hover:cursor-pointer'
  ],
  option: ['bg-white', 'hover:bg-primaryBackgroundHover']
});

export const DropdownSelect: React.FC<DropdownSelectProps> = React.memo(
  ({ options, onChange, defaultLabel, current, fullWidth }) => {
    const { t } = useTranslation();
    const styles = useStyles({ fullWidth });

    const handleChange = (event) => {
      const selectedValue = event.target.value;
      const selectedOption = options.find((option) => option.value === selectedValue);
      onChange && onChange(selectedOption);
    };

    return (
      <div className={styles.root}>
        <select
          className={styles.select}
          onChange={handleChange}
          value={current?.value || ''}
          title={t('SELECT_DROPDOWN')}>
          <option value="" disabled>
            {defaultLabel || t('SELECT_ONE')}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value} className={styles.option}>
              {option.label || option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

DropdownSelect.defaultProps = {
  fullWidth: true
};

DropdownSelect.displayName = 'DropdownSelect';
