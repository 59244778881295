import { Dayjs } from 'dayjs';
import { CalendarIcon } from 'icons/monochrome/CalendarIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { Input } from '../Input';
import { InputProps } from '../Input/Input';

type CalendarInputProps = {
  value?: Dayjs;
  isDateRange?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
} & ClickEventProp &
  Omit<InputProps, 'value'>;

const useStyles = makeStyles<CalendarInputProps>()({
  input: ({ fullWidth, noPadding }) => [
    {
      'w-[146px]': !fullWidth,
      'pr-0': noPadding
    },
    'text-secondaryText placeholder:text-neutralText',
    'cursor-pointer'
  ],
  icon: (props) => [
    'text-secondaryIcon',
    {
      'text-tmBlue': props?.hasFocusBorder
    }
  ]
});

const DateInput = memo(
  ({
    onClick,
    value,
    disableStyle,
    hasFocusBorder,
    name,
    fullWidth,
    noPadding
  }: CalendarInputProps) => {
    const styles = useStyles({ hasFocusBorder, fullWidth, noPadding });

    return (
      <Input
        name={name}
        placeholder="mm/dd/yyyy"
        autoComplete="off"
        className={styles.input}
        onClick={onClick}
        paddingX="LG"
        value={value?.format('MM/DD/YYYY')}
        prepend={<CalendarIcon className={styles.icon} />}
        disableStyle={disableStyle}
        hasFocusBorder={hasFocusBorder}
        readOnly
      />
    );
  }
);

DateInput.displayName = 'DateInput';

export { DateInput };
