'use client';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const useHubSpotFormFields = () => {
  const { getValues, setValue } = useFormContext();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  useEffect(() => {
    const initialValues = getValues('checkedItems') || [];
    setCheckedItems(initialValues);
  }, [getValues]);

  const handleCheckboxChange = useCallback(
    (field: string, optionValue: string) => {
      let currentValue: string[] = getValues(field) || [];
      if (currentValue.includes(optionValue)) {
        currentValue = currentValue.filter((value) => value !== optionValue);
      } else {
        currentValue = [...currentValue, optionValue];
      }

      setCheckedItems(currentValue);
      setValue(field, currentValue, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
    },
    [getValues, setValue]
  );

  return { handleCheckboxChange, checkedItems };
};
