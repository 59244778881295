'use client';
import { DateInput } from 'components/common/DatePicker/DateInput';
import { useDatePicker } from 'hooks/useDatePicker';
import React, { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Calendar, CalendarWrapper } from './Calendar';
import { CalendarProps } from './Calendar/Calendar';
import { TimePicker } from './TimePicker';

export type DatePickerProps = {
  hasTimePicker?: boolean;
  toggleOnSelect?: boolean;
  fullWidth?: boolean;
} & Pick<CalendarProps, 'id' | 'handleValidDate'>;

export const DatePicker: React.FC<DatePickerProps> = memo(
  ({
    handleValidDate,
    hasTimePicker = false,
    toggleOnSelect = false,
    id = 'date_picker',
    fullWidth = false
  }) => {
    const datePickerName = id;
    const { showDatePicker, toggleDatePicker, datePickerValue, handleChange } =
      useDatePicker(toggleOnSelect);

    const { setValue } = useFormContext();
    const handleChangeWithFormContext = (value) => {
      setValue(datePickerName, value);
      handleChange(value);
    };

    // useEffect necessary to set a default value for the input when the form renders
    useEffect(() => {
      setValue(datePickerName, datePickerValue);
    }, [datePickerName, datePickerValue]);

    return (
      <CalendarWrapper
        fullWidth={fullWidth}
        onClickAway={() => showDatePicker && toggleDatePicker()}>
        <Calendar
          id={id}
          handleValidDate={handleValidDate}
          input={React.createElement(() => {
            return (
              <DateInput
                name={datePickerName}
                onClick={toggleDatePicker}
                value={datePickerValue}
                hasFocusBorder={showDatePicker}
                fullWidth={fullWidth}
              />
            );
          })}
          changeHandler={handleChangeWithFormContext}
          value={datePickerValue}
          show={showDatePicker}
          append={hasTimePicker && <TimePicker />}
        />
      </CalendarWrapper>
    );
  }
);

DatePicker.displayName = 'DatePicker';
