import { Asset } from 'components/contentful/Asset';
import { makeStyles } from 'lib/makeStyles';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

import { ScrollSlideAnimation, ScrollSlideAnimationProps } from './ScrollSlideAnimation';

type PhoneAssetProps = {
  entry: SerializedComponentItem;
  className?: string;
} & Pick<ScrollSlideAnimationProps, 'yRange'>;

const useStyles = makeStyles({
  root: (props) => [
    'w-1/3 px-12 -mb-12',
    'hidden md:flex justify-end items-start',
    'bg-image-hero-homepage-right bg-size-100 bg-left-bottom bg-no-repeat',
    props.className
  ]
});

export const PhoneAsset = ({ entry, yRange, className }: PhoneAssetProps) => {
  const styles = useStyles({ className });
  return (
    <div className={styles.root}>
      <ScrollSlideAnimation yRange={yRange}>
        <Asset entry={entry} width={315} height={629} />
      </ScrollSlideAnimation>
    </div>
  );
};
