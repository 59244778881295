import React, { useRef } from 'react';
import { useClickAway } from 'react-use';

type CalendarWrapperProps = {
  onClickAway?: GenericHandler;
  fullWidth?: boolean;
} & ChildrenProp;

const CalendarWrapper: React.FC<CalendarWrapperProps> = ({ children, onClickAway, fullWidth }) => {
  const calendarRef = useRef();

  useClickAway(calendarRef, () => {
    onClickAway && onClickAway();
  });

  return (
    <div className={fullWidth ? 'w-full' : 'w-max'} ref={calendarRef}>
      {children}
    </div>
  );
};

CalendarWrapper.displayName = 'CalendarWrapper';

export { CalendarWrapper };
