import { CheckboxGroup } from 'components/common/Input/SelectInputGroup';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { useHubSpotFormFields } from 'hooks/useHubSpotForm';
import { sortHubSpotFormOptions } from 'lib/hubspot/sortHubSpotFormOptions';
import { memo, useMemo } from 'react';

export const HubSpotMultipleCheckboxField = memo((field: HubSpotFormFieldType) => {
  const { handleCheckboxChange, checkedItems } = useHubSpotFormFields();
  const sortedOptions = useMemo(() => sortHubSpotFormOptions(field.options), [field.options]);

  return (
    <CheckboxGroup
      col={2}
      name={field.name}
      onChange={(e) => handleCheckboxChange(field.name, e.target.value)}
      options={sortedOptions.map((option, index) => ({
        ...option,
        name: `${field.name}[${index}]`,
        checked: checkedItems.includes(option.value)
      }))}
    />
  );
});

HubSpotMultipleCheckboxField.displayName = 'HubSpotMultipleCheckboxField';
