'use client';
import { ContentfulContext } from 'hooks/useContentful';
import { useDriftWidgetScript } from 'hooks/useDriftWidget';
import React from 'react';
import { PageProps } from './Page';
import { PageContext } from './PageContext';

export const ClientPageProvider: React.ComponentType<Omit<PageProps, 'disableScroll'>> = ({
  contentfulQuery,
  children,
  ...pageContextValues
}) => {
  useDriftWidgetScript();

  return (
    <ContentfulContext.Provider value={{ contentfulQuery }}>
      <PageContext.Provider value={pageContextValues}>{children}</PageContext.Provider>
    </ContentfulContext.Provider>
  );
};
