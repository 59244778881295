'use client';
import { Modal } from 'components/common/Modal';
import { HubSpotFormContent } from 'components/tenstreet/HubSpotFormModal/HubSpotFormContent';
import { useHubSpotForm } from 'hooks/useHubSpotForm';
import { memo } from 'react';

export type HubSpotFormProps = {
  portalId?: string;
  formId?: string;
};

export const HubSpotFormModal = memo(() => {
  const {
    formFields,
    loading,
    loadingError,
    show,
    handleSubmitFormData,
    handleCloseHubSpotForm,
    enableHubSpotModal: enable,
    toggleHubSpotModal: toggle
  } = useHubSpotForm();

  if (loadingError) return null;

  return (
    <Modal
      as="form"
      size="md"
      hasMobileFullHeight
      open={show}
      showClose={false}
      defaultPadding={false}
      toggleState={{ disable: handleCloseHubSpotForm, enable, toggle, status: show }}
      onSubmit={handleSubmitFormData}>
      <HubSpotFormContent
        loading={loading}
        formFields={formFields}
        onClose={handleCloseHubSpotForm}
      />
    </Modal>
  );
});

HubSpotFormModal.displayName = 'HubSpotFormModal';
