import { useCallback, useMemo } from 'react';

export const useScrollLink = (url?: string) => {
  const isScrollLink = useMemo(() => url?.includes('#'), [url]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isScrollLink) {
        e.preventDefault();
        const targetId = url?.split('#')[1];
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    [isScrollLink, url]
  );

  return { isScrollLink, handleClick };
};
