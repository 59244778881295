'use client';
import { SearchInputProps } from 'components/common/Input';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful';
import { useDesktopBreakpoint } from 'hooks/useUI/useDesktopBreakpoint';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { memo, useMemo } from 'react';

const PublicSearchInput = dynamic<SearchInputProps>(
  () => import('components/PublicSearch/PublicSearch').then((mod) => mod.PublicSearch),
  { ssr: false }
);

const PlaceSearchInput = dynamic(
  () =>
    import('components/Place/PlaceSearch/PlaceSearchInput/PlaceSearchInput').then(
      (mod) => mod.PlaceSearchInput
    ),
  { ssr: false }
);

export const NavbarSearch = memo(() => {
  const pathname = usePathname();
  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');

  if (!publicNavbarContent) {
    return <></>;
  }

  const [searchInput] = useContentfulUiComponentItems(publicNavbarContent, 'SearchInput');
  const isDesktop = useDesktopBreakpoint();
  const includesPlacesPath = useMemo(() => pathname.includes('/places'), [pathname]);

  return (
    <>
      {includesPlacesPath && isDesktop ? (
        <PlaceSearchInput />
      ) : (
        <PublicSearchInput placeholder={searchInput.primaryText} />
      )}
    </>
  );
});

NavbarSearch.displayName = 'NavbarSearch';
