import { makeStyles } from 'lib/makeStyles';
import React, { memo, ReactNode } from 'react';

import { DayTimePicker } from './DayTimePicker';
import { HourPicker } from './HourPicker';
import { MinutePicker } from './MinutePicker';
import { TimePickerLabel } from './TimePickerLabel';

export type TimePickerProps = {
  label?: string | ReactNode;
  showMinutes?: boolean;
};

export const minutesOptions = [0, 15, 30, 45];
export const hoursOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export enum DayTimeOptions {
  AM = 'AM',
  PM = 'PM'
}

export const dayTimeOptions = [DayTimeOptions.AM, DayTimeOptions.PM];
export const formatMinute = (item) => (item === 0 ? '00' : item.toString());
export const formatHour = (item) => (item < 10 && item > 0 ? `0${item}` : item.toString());

export const useStyles = makeStyles()({
  root: 'flex justify-between items-center p-2 pt-4 border-t border-secondaryBorder',
  flexRow: 'flex space-x-2',
  innerFlexRow: 'flex space-x-1',
  drawerClassName: 'max-h-[100px] overflow-y-scroll',
  marginAuto: 'm-auto'
});

const TimePicker = memo(({ label = 'Time', showMinutes = true }: TimePickerProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {typeof label === 'string' ? <TimePickerLabel>{label}</TimePickerLabel> : label}
      <div className={styles.flexRow}>
        <div className={styles.innerFlexRow}>
          <HourPicker />
          <MinutePicker showMinutes={showMinutes} />
        </div>
        <DayTimePicker />
      </div>
    </div>
  );
});

TimePicker.displayName = 'TimePicker';

export { TimePicker };
