import { PanelItem } from 'components/layout/Panel/PanelItem';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useSelectGroupGridStyles } from 'components/common/Input/SelectInputGroup/SelectGroupSection';
import { CheckboxInput, SelectInputProps as CheckboxInputProps } from '../SelectInput';
import {
  SelectGroupType as RadioGroupType,
  InputOptionType as RadioOptionType
} from './RadioGroup';
import { SelectGroupTitle } from './SelectGroupTitle';

export type InputOptionType = {
  indeterminate?: boolean;
  icon?: React.ReactNode;
} & RadioOptionType &
  Required<Pick<JSX.IntrinsicElements['input'], 'name'>>;

export type Props = {
  options: InputOptionType[];
  locked?: boolean;
  onLockedClick?: () => void;
  onChange?: GenericHandler;
} & RadioGroupType;

export const CheckboxGroup = ({
  borderless = true,
  title,
  options,
  col,
  locked,
  onChange,
  onLockedClick
}: Props) => {
  const gridStyles = useSelectGroupGridStyles({ col, className: 'py-1' });

  return (
    <div className={gridStyles.grid}>
      {title && <SelectGroupTitle title={title} />}
      {options.map((option, index) => {
        const {
          label,
          disabled,
          indeterminate,
          name,
          required,
          checked,
          defaultChecked,
          ...inputProps
        } = option;
        const { control, setValue } = useFormContext();
        return (
          <PanelItem
            key={`${name}_${index}_`}
            intent="primary"
            active={checked || defaultChecked}
            enableBorder={!borderless}
            disableFlex
            enableBackground
            enableHover
            padding={false}
            rounded="SM">
            <Controller
              name={name}
              defaultValue={checked}
              rules={{ required }}
              control={control}
              render={({ field: { value, ref } }) => (
                <CheckboxInput
                  className="w-full"
                  disabled={disabled}
                  indeterminate={indeterminate}
                  name={`checkbox_${name}_${index}_`}
                  checked={value}
                  // this onChange callback is here to avoid "react fewer hooks" issue if using useController
                  onChange={(e) => {
                    if (!value && locked) {
                      onLockedClick();

                      return;
                    }

                    onChange && onChange(e);
                    return setValue(name, !value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true
                    });
                  }}
                  {...(inputProps as CheckboxInputProps)}
                  ref={ref}>
                  {label ?? value}
                </CheckboxInput>
              )}
            />
          </PanelItem>
        );
      })}
    </div>
  );
};

CheckboxGroup.displayName = 'CheckboxGroup';
