import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CalendarIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1V2H11V1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V2C14.1046 2 15 2.89543 15 4V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V4C1 2.89543 1.89543 2 3 2V1ZM3 7.5C3 7.22386 3.22386 7 3.5 7H4.5C4.77614 7 5 7.22386 5 7.5V8.5C5 8.77614 4.77614 9 4.5 9H3.5C3.22386 9 3 8.77614 3 8.5V7.5ZM3.5 11C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13H4.5C4.77614 13 5 12.7761 5 12.5V11.5C5 11.2239 4.77614 11 4.5 11H3.5ZM7 7.5C7 7.22386 7.22386 7 7.5 7H8.5C8.77614 7 9 7.22386 9 7.5V8.5C9 8.77614 8.77614 9 8.5 9H7.5C7.22386 9 7 8.77614 7 8.5V7.5ZM11.5 7C11.2239 7 11 7.22386 11 7.5V8.5C11 8.77614 11.2239 9 11.5 9H12.5C12.7761 9 13 8.77614 13 8.5V7.5C13 7.22386 12.7761 7 12.5 7H11.5Z"
      fill="currentColor"
    />
  </Glyph>
));

CalendarIcon.displayName = 'CalendarIcon';

export { CalendarIcon };
