'use client';
import { ContentfulButton } from 'components/common/Button/ContentfulButton';
import { getHubSpotId } from 'lib/hubspot/getHubSpotId';
import { memo, useMemo } from 'react';
import { useEnableHubSpotFormModal } from 'recoil/hubspotFormAtom/hubspotFormAtomHooks';
import { truckMapConfig } from 'truckMapConfig';
import { SerializedComponentItem } from 'types/contentful';

export const TenstreetContentfulButton = memo(
  ({ button, onClick }: { button: SerializedComponentItem } & ClickEventProp) => {
    if (!button) return;

    const formId = getHubSpotId(
      truckMapConfig.isStaging,
      truckMapConfig.defaultHubSpotFormId,
      button.hubspotFormId
    );

    const portalId = getHubSpotId(
      truckMapConfig.isStaging,
      truckMapConfig.defaultHubSpotPortalId,
      button.hubspotPortalId
    );

    const enableHubSpotFormModal = useEnableHubSpotFormModal({ portalId, formId });

    const handleClick = useMemo(
      () => onClick ?? enableHubSpotFormModal,
      [onClick, enableHubSpotFormModal]
    );

    return <ContentfulButton button={button} onClick={handleClick} />;
  }
);

TenstreetContentfulButton.displayName = 'TenstreetContentfulButton';
