import { useContentfulSerializer } from 'hooks/useContentful';
import { sectionLayoutsMap } from 'models/LayoutModel';
import React from 'react';
import { IUIComponent } from 'types/generated/contentful';

export type GenericSectionProps = {
  entry: IUIComponent;
};

export const GenericSection: React.ComponentType<GenericSectionProps> = React.memo(({ entry }) => {
  const Component = sectionLayoutsMap.get(entry.fields.sectionLayout);
  const props = useContentfulSerializer(entry);

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
});

GenericSection.displayName = 'GenericSection';
