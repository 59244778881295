import { useTranslation } from 'hooks/useContentful/useTranslation';
import { SearchIcon } from 'icons/monochrome/SearchIcon';

import { SearchIconWrapper } from './SearchIconWrapper';

type SearchInputIconProps = {
  iconClassName?: string;
  wrapperClassName?: string;
};

export const SearchInputIcon = ({ iconClassName, wrapperClassName }: SearchInputIconProps) => {
  const { t } = useTranslation();

  return (
    <SearchIconWrapper className={wrapperClassName} title={`${t('SEARCH')} TruckMap`}>
      <SearchIcon className={iconClassName} />
    </SearchIconWrapper>
  );
};
