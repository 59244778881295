'use client';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

import { ErrorPageHeroBackground } from './ErrorPageHeroBackground';
import { ErrorPageHeroContent } from './ErrorPageHeroContent';

const useStyles = makeStyles({
  outerWrapper: 'flex relative bg-primaryBackground'
});

const ErrorPageHero = React.memo((props: UIComponentProps) => {
  const styles = useStyles();

  return (
    <div className={styles.outerWrapper}>
      <ErrorPageHeroBackground />
      <ErrorPageHeroContent {...props} />
    </div>
  );
});

ErrorPageHero.displayName = 'ErrorPageHero';

export { ErrorPageHero };
