import { PanelItem } from 'components/layout/Panel/PanelItem';

import { RadioInput } from '../SelectInput';
import { InputOptionType, SelectGroupType } from './RadioGroup';

type Props = {
  option: InputOptionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValue: any;
} & Pick<SelectGroupType, 'borderless' | 'optionClassName' | 'hasSelectedSticky'> &
  Required<Pick<JSX.IntrinsicElements['input'], 'name'>>;

export const RadioGroupItem = ({
  option,
  name,
  borderless,
  hasSelectedSticky,
  currentValue,
  optionClassName
}: Props) => {
  const { value, label, ...optionProps } = option;

  return (
    <PanelItem
      hasSelectedSticky={hasSelectedSticky}
      className={optionClassName}
      enableBorder={!borderless}
      active={currentValue === value}
      padding={false}
      rounded="SM"
      enableHover
      enableBackground
      intent="primary"
      fullWidth>
      <RadioInput value={value} checked={currentValue === value} {...optionProps} name={name}>
        {label ?? value}
      </RadioInput>
    </PanelItem>
  );
};

RadioGroupItem.displayName = 'RadioGroupItem';
