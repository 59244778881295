import { isPlaceRecord } from '@truckmap/common';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import {
  usePlace,
  usePlaceCreateModal,
  usePlaceDetailsHiddenForm
} from 'hooks/usePlaceDetails/usePlaceDetails';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { PlaceDetailsEmptyValueProps } from '../Values/PlaceDetailsEmptyValue';

type PlaceDetailsHiddenFormProps = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ComponentType<any>;
  submitText?: string | React.ReactNode;
} & Pick<PlaceDetailsEmptyValueProps, 'showForm'> &
  ParentClassNameProp;

const useStyles = makeStyles({
  closeButton: (props: PlaceDetailsHiddenFormProps) => ['w-auto', props.className]
});

const PlaceDetailsHiddenForm = React.memo(
  ({ id, children, className, submitText, showForm }: PlaceDetailsHiddenFormProps) => {
    const styles = useStyles({ className });
    const place = usePlace();
    const { enable: showPlaceCreateModal } = usePlaceCreateModal();
    const { toggleActiveForm, formId } = usePlaceDetailsHiddenForm(id);

    const buttonAction = isPlaceRecord(place) ? toggleActiveForm : showPlaceCreateModal;

    return !showForm ? (
      <a className={styles.closeButton} href={formId} onClick={buttonAction}>
        <MetaText color="tmBlue" as="label" enableHover>
          {submitText ?? <Translation textKey="BUTTON_SUBMIT" />}
        </MetaText>
      </a>
    ) : (
      React.createElement(children)
    );
  }
);

PlaceDetailsHiddenForm.displayName = 'PlaceDetailsHiddenForm';

export { PlaceDetailsHiddenForm };
