import { localClient } from 'clients/local';
import useSWR from 'swr';
import { AppStoreReviews } from 'types/api';

import { AppStoreReviewContent } from './AppStoreReviewContent';

type Props = {
  provider: 'apple' | 'android';
  loading?: boolean;
};

export const AppStoreReview: React.FC<Props> = (props) => {
  const { data: appReviews = null } = useSWR(
    'app-reviews',
    async () => {
      const { data: appReviews } = await localClient.get<AppStoreReviews>('app-reviews.json');

      return appReviews;
    },
    {
      fallbackData: {
        appleRating: 5,
        androidRating: 5
      },
      revalidateOnFocus: false
    }
  );

  const count = appReviews[props.provider + 'Count'];

  return <AppStoreReviewContent {...props} count={count} />;
};
