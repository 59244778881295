'use client';
import { useToggle } from 'hooks/useToggle';
import { createContext, useContext } from 'react';

export type ModalToggleState = ReturnType<typeof useToggle>;

export type ModalContextType = {
  toggleState?: ModalToggleState;
};

export const ModalContext = createContext<ModalContextType>(null);

export const useModalContext = () => useContext(ModalContext) ?? {};
