'use client';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { fetchHubSpotFormDefinition } from 'lib/hubspot';
import { useState } from 'react';
import useSWRImmutable from 'swr/immutable';

export const useHubSpotFormDefinition = (formId) => {
  const [formFields, setFormFields] = useState<HubSpotFormFieldType[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);

  const { data: hubspotFormData, error } = useSWRImmutable(
    `hubspot-form-definition-${formId}`,
    async () => {
      try {
        if (!formId) return;

        const formData = await fetchHubSpotFormDefinition(formId);

        setFormFields(formData.fieldGroups.flatMap((group) => group.fields));
        setLoading(false);

        return hubspotFormData;
      } catch (err) {
        console.warn(`hubspotFormDefinition response is ${error.status}`);
        setLoadingError(error.message);
        setLoading(false);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: true
    }
  );

  return { formFields, loading, loadingError };
};
