'use client';
import { PhoneAsset } from 'components/common/PhoneAsset';
import { SolutionsDevelopersHeroProps } from 'components/Solutions/Developers/SolutionsDevelopersHero';
import { useBreakpoints } from 'hooks/useUI/useBreakpoints';
import { memo } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export const ClientSolutionsDevelopersPhoneAsset = memo(
  ({ items, className }: Pick<SolutionsDevelopersHeroProps, 'items'> & ParentClassNameProp) => {
    const [imageItem] = items.get('Image');
    const { currentBreakpoint, breakpoints } = useBreakpoints();

    return (
      <PhoneAsset
        entry={imageItem}
        yRange={[
          0,
          currentBreakpoint === breakpoints.MD || !truckMapConfig.isClientSideRender ? 0 : 1700
        ]}
        className={className}
      />
    );
  }
);

ClientSolutionsDevelopersPhoneAsset.displayName = 'ClientSolutionsDevelopersPhoneAsset';
