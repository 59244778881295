'use client';
import { memo } from 'react';
import ReactMarkdown from 'react-markdown';

import { components } from './components';

type Props = {
  markdown: string;
} & ParentClassNameProp;

const Markdown = memo(({ markdown, className }: Props) => (
  <ReactMarkdown className={className} components={components} includeElementIndex>
    {markdown}
  </ReactMarkdown>
));

Markdown.displayName = 'Markdown';

export { Markdown };
