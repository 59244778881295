'use client';
import { appStores } from 'components/Homepage/HomepageSectionDownload/HomepageSectionDownload';
import { makeStyles } from 'lib/makeStyles';
import { useParams } from 'next/navigation';
import React, { useState } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export type SupportedPlatforms = 'ios' | 'android';

export type AppStoreBadgeProps = {
  url: string;
  defaultLocale?: string;
  platform: SupportedPlatforms;
  disableAnimation?: boolean;
} & Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'target'> &
  ParentClassNameProp;

const useStyles = makeStyles<Pick<AppStoreBadgeProps, 'className' | 'disableAnimation'>>()({
  root: (props) => [
    'w-full',
    {
      'transform transition duration-300 ease-in-out hover:-translate-y-0.5':
        !props?.disableAnimation
    },
    props?.className
  ]
});

const getImage = (
  platform: SupportedPlatforms,
  locale = truckMapConfig.i18n.defaultLocale.toLowerCase(),
  code = locale
): { src: string; width: number; height: number } => {
  switch (platform) {
    case 'ios':
      return {
        src: `https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/${
          locale.startsWith('en-') ? 'en-us' : locale
        }.svg`,
        width: 170,
        height: 57
      };
    case 'android':
      return {
        src: `/images/google-play-badge/${code}_get.svg`,
        width: 192,
        height: 57
      };
  }
};

export const AppStoreBadge: React.ComponentType<AppStoreBadgeProps> = ({
  url = appStores[0].url,
  platform = appStores[0].platform,
  defaultLocale = 'en-us',
  target = '_self',
  disableAnimation = false,
  className
}) => {
  const params = useParams();
  let [locale] = params?.locale ?? [defaultLocale];

  const styles = useStyles({ disableAnimation, className });

  let shortCode = (locale = locale?.toLowerCase() ?? defaultLocale);
  const expeptionLocale = ['zh-cn', 'zh-tw'];
  if (expeptionLocale.indexOf(locale) === -1) {
    shortCode = locale.split(/[_-]/)[0];
  }

  const [image, setImage] = useState(getImage(platform, locale, shortCode));

  const setDefaultImage = () => {
    setImage(getImage(platform, defaultLocale, shortCode));
  };

  return (
    image && (
      <a className={styles.root} href={url} target={target} aria-label={`Download ${platform} app`}>
        <img {...image} alt={platform} className="h-full" onError={setDefaultImage} />
      </a>
    )
  );
};

AppStoreBadge.displayName = 'AppStoreBadge';
