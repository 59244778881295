import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';

import { useRawToggle } from '../useToggle';

/**
 * Handles state for DatePicker
 * @param toggleOnSelect boolean
 * @returns {
    showDatePicker,
    toggleDatePicker,
    datePickerValue,
    setDatePickerValue,
    handleChange
  }
 */
export const useDatePicker = (toggleOnSelect?: boolean) => {
  const [showDatePicker, toggleDatePicker] = useRawToggle();
  const [datePickerValue, setDatePickerValue] = useState<Dayjs>(dayjs());

  const handleChange = useCallback(
    (value) => {
      setDatePickerValue(value);
      toggleOnSelect && toggleDatePicker();
    },
    [toggleOnSelect, setDatePickerValue, toggleDatePicker]
  );

  return {
    showDatePicker,
    toggleDatePicker,
    datePickerValue,
    setDatePickerValue,
    handleChange
  };
};
