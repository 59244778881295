'use client';
import { PhoneAsset } from 'components/common/PhoneAsset';
import { useHomepageFeatures } from 'hooks/useHomepageFeatures';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

export const ClientPhoneAsset = React.memo(({ items }: Pick<UIComponentProps, 'items'>) => {
  const [state] = useHomepageFeatures();
  const featureBullets = items.get('FeatureBullet');
  const featureImageItem = featureBullets.find((bullet) => bullet.id === state);
  const [imageItem] = items.get('Image');

  return <PhoneAsset entry={featureImageItem ?? imageItem} yRange={[0, 550]} />;
});

ClientPhoneAsset.displayName = 'ClientPhoneAsset';
