import React, { memo, useCallback, useState } from 'react';

import { DropdownList } from '../../DropdownList';
import { ListOption } from '../../DropdownList/DropdownListOptions';
import { dayTimeOptions } from './TimePicker';
import { useStyles } from './TimePicker';

const DayTimePicker = memo(() => {
  const styles = useStyles();
  const [dayTimeValue, setDayTimeValue] = useState<ListOption>(null);
  const createDayTimeOptions = useCallback(() => {
    return dayTimeOptions.map((item, index) => ({
      value: item,
      customSelected: dayTimeValue?.value === item,
      initialValue: index === 0
    }));
  }, [dayTimeValue, dayTimeOptions]);

  return (
    <DropdownList
      isSmall
      drawerClassName={styles.drawerClassName}
      options={createDayTimeOptions()}
      onChange={setDayTimeValue}
    />
  );
});

DayTimePicker.displayName = 'DayTimePicker';

export { DayTimePicker };
