import { GlyphProps } from 'components/common/Glyph';
import dynamic from 'next/dynamic';

export const getExportedModule = (path: string | undefined): string | null => {
  if (typeof path !== 'string' || path.length === 0) {
    return null;
  }
  const tracedPath = path.split('/');
  return tracedPath.length > 0 ? tracedPath[tracedPath.length - 1] : null;
};

export const iconComponentResolver = (iconName: string) => {
  if (!iconName) return null;

  return dynamic<GlyphProps>(() =>
    import('icons/' + iconName)
      .then((mod) => {
        const ExportedComponent = mod[getExportedModule(iconName)];
        if (!ExportedComponent) {
          throw new Error(`Unable to find icon: ${iconName}`);
        }
        return ExportedComponent;
      })
      .catch((error) => {
        console.error(`Error loading icon: ${iconName}`, error);
        return () => null;
      })
  );
};
