import cx from 'clsx';
import { EnableHoverType } from 'components/common/Typography';
import { getIconSize } from 'lib/getIconSize';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { StaticImage } from '../Image/StaticImage';

// folders in https://github.com/TruckMap/truckmap-static/tree/main/public/assets/local/images/icons
export type AssetIconType =
  | 'app-category'
  | 'companies'
  | 'maneuvers'
  | 'monochrome'
  | 'occupations'
  | 'place-annotation-types'
  | 'place-meta-types'
  | 'place-reviews'
  | 'place-types'
  | 'road-attributes'
  | 'trip'
  | 'weather';

export type IconProps = {
  size?: 'XS' | 'SM' | 'MD' | 'LG';
  type?: AssetIconType;
  enableHover?: EnableHoverType;
  invertColor?: boolean;
};

export type AssetIconProps = {
  slug: string;
  alt?: string;
} & IconProps;

const getOpacityHoverClassName = (enableHover: EnableHoverType) =>
  enableHover === 'group' ? 'group-hover:opacity-20' : 'hover:opacity-20';

const useIconStyles = makeStyles<IconProps>()({
  wrapper: 'relative shrink-0',
  fullSize: 'w-full h-full',
  absolutePosition: 'absolute top-0 left-0',
  hoverStyle: ({ enableHover }: IconProps) => [
    {
      hidden: !enableHover
    },
    'absolute top-0 left-0 opacity-0',
    Boolean(enableHover) && getOpacityHoverClassName(enableHover)
  ],
  // Custom filter class adds webkit fix for Safari and other browsers
  iconImage: (props) => [{ 'filter invert invert-filter': props.invertColor }]
});

export const AssetIcon = React.memo(
  ({
    size = 'SM',
    slug,
    type = 'place-meta-types',
    enableHover = true,
    alt = '',
    invertColor = false
  }: AssetIconProps) => {
    const iconStyles = useIconStyles({ size, enableHover, invertColor });
    const iconSize = getIconSize({ size });

    // we double image size for better pixel density on retina display
    const iconImageSize = 2 * iconSize;

    return (
      <div className={iconStyles.wrapper}>
        <div style={{ width: iconSize, height: iconSize }}>
          <StaticImage
            width={iconImageSize}
            height={iconImageSize}
            format="png"
            quality={100}
            className={iconStyles.iconImage}
            src={`icons/${type}/${slug}.png`}
          />
        </div>
        <div className={cx(iconStyles.absolutePosition, iconStyles.hoverStyle)}>
          <img width={iconSize} height={iconSize} alt={alt} src="/images/icon-hover-square.svg" />
        </div>
      </div>
    );
  }
);

AssetIcon.displayName = 'AssetIcon';
