'use client';
import { useCallback } from 'react';
import { useSetAutoCompleteVisible } from 'recoil/autoComplete';

import { useBreakpoints } from '../useUI/useBreakpoints';
import { useScrollButton } from '../useUI/useScrollButton';
import { usePublicSearchInput } from './usePublicSearchInput';

/**
 * Allows the use of onFocus from usePublicSearchInput hook in outside components
 * @returns useCallback method to scroll to top and open the SearchInput component
 */

export const usePublicSearchInputOpen = () => {
  const { scrollToTop } = useScrollButton({});
  const { onFocus } = usePublicSearchInput();
  const setSearchVisible = useSetAutoCompleteVisible('publicSearch');
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  return useCallback(() => {
    if (currentBreakpoint !== breakpoints.LG) {
      setSearchVisible(true);
    }

    if (
      currentBreakpoint === breakpoints.LG ||
      currentBreakpoint === breakpoints.XL ||
      currentBreakpoint === breakpoints.XXL
    ) {
      scrollToTop();
    }

    return onFocus();
  }, [currentBreakpoint]);
};
