import { Transition } from '@headlessui/react';
import { Asset } from 'components/contentful/Asset';
import { useSliderPaginationContext } from 'hooks/useSliderPagination';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful';

const useStyles = makeStyles({
  root: [
    'top-0',
    'scale-125 sm:scale-100',
    'h-full lg:w-full',
    'transform translate-y-10 md:-translate-y-20 lg:translate-y-0',
    'lg:absolute',
    'lg:z-20'
  ],
  ghostItem: ['opacity-0'],
  animationWrapper: ['flex justify-center', 'absolute top-0', 'w-full']
});

const SliderImage = React.memo(() => {
  const { currentPage, items } = useSliderPaginationContext();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {/* ghost item necessary to grow the container div */}
      <div className={styles.ghostItem}>
        <Asset entry={items[0]} />
      </div>
      {(items as SerializedComponentItem[]).map((entry, index) => {
        const pageIndex = index + 1;
        const isCurrentPage = pageIndex === currentPage;
        return (
          <div key={entry.id} className={styles.animationWrapper}>
            <Transition
              appear={true}
              show={isCurrentPage}
              unmount={false}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Asset entry={entry} />
            </Transition>
          </div>
        );
      })}
    </div>
  );
});

SliderImage.displayName = 'SliderImage';

export { SliderImage };
