import { DatePicker } from 'components/common/DatePicker/DatePicker';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const HubSpotDatePickerField = memo((field: HubSpotFormFieldType) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue=""
      render={({ field: { onChange } }) => (
        <DatePicker
          id={field.name}
          hasTimePicker={false}
          toggleOnSelect
          fullWidth
          handleValidDate={(date) => {
            // Format the date value
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            onChange(formattedDate);
            return true;
          }}
        />
      )}
    />
  );
});

HubSpotDatePickerField.displayName = 'HubSpotDatePickerField';
