'use client';
import clsx from 'clsx';
import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import React from 'react';

type Props = {
  className?: string;
  fullLogo?: boolean;
  disableLink?: boolean;
  show?: boolean;
};

export const Logo = React.memo(
  ({ className, fullLogo = false, disableLink = false, show = true }: Props) => {
    const classes = clsx('w-auto max-w-none focus-default', className);
    const url = fullLogo ? '/images/logo-full.svg' : '/images/logo.svg';
    const logo = <img src={url} alt="TruckMap" width={43} height={40} className={classes} />;

    if (disableLink) {
      return <OpacityTransition show={show}>{logo}</OpacityTransition>;
    }

    return (
      <OpacityTransition show={show}>
        <NonPrefetchLink href="/" aria-label="TruckMap">
          {logo}
        </NonPrefetchLink>
      </OpacityTransition>
    );
  }
);

Logo.displayName = 'Logo';
