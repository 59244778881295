import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { MetaText } from '../../Typography';

type Props = {
  subheading?: string;
  borderTop?: boolean;
  borderBottom?: boolean;
  hasBackground?: boolean;
  heading: string;
  prepend?: React.ReactNode;
} & ParentClassNameProp;

const useStyles = makeStyles<Props>()({
  root: (props) => [
    'w-full',
    'p-1',
    'mb-1',
    {
      'bg-primaryBackgroundHover rounded-md': props.hasBackground
    },
    {
      'border-t pt-3': props.borderTop,
      'border-b pb-3': props.borderBottom,
      'border-gray-040': props.borderTop || props.borderBottom
    },
    props.className
  ],
  subheading: ['text-neutralTextLoud'],
  panelItem: ['!items-start']
});

const ModalHeading = memo((props: Props) => {
  const { subheading, heading, prepend } = props;
  const styles = useStyles(props);

  return (
    <div className={styles.root}>
      <PanelItem className={styles.panelItem}>
        {prepend && prepend}
        <span>
          <MetaText bold as="h2">
            {heading}
          </MetaText>
          {subheading && <MetaText className={styles.subheading}>{subheading}</MetaText>}
        </span>
      </PanelItem>
    </div>
  );
});

ModalHeading.displayName = 'ModalHeading';

export { ModalHeading };
