'use client';
import { getHubSpotUTMCampaign } from 'lib/hubspot';
import { usePathname } from 'next/navigation';

export const useHubspotUTMFields = () => {
  const pathname = usePathname();
  const path = pathname.split('?')[0];
  const utmCampaign = getHubSpotUTMCampaign(path);

  return {
    utm_campaign: utmCampaign,
    utm_source: 'truckmap',
    utm_medium: 'website'
  };
};
