'use client';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { homepageFeaturesAtom } from 'recoil/homepageFeatures';
/**
 * Takes a UIComponent from contentful and organizes/serializes the items.
 * @param input UIComponent
 * @returns Entries serialized and organized by type.
 */
export const useHomepageFeatures = (id?: string): [string, () => void, boolean] => {
  const [state, setState] = useRecoilState(homepageFeaturesAtom);
  const setSelected = useCallback(() => setState(id), [id]);

  const isSelected = state === id;

  return [state, setSelected, isSelected];
};
