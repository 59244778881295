import { NavLink } from 'components/common/NavLink';
import { RuntimeFeatureEnabled } from 'components/FeatureEnabled';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful/useContentful';
import { useMobileNavigationLinks } from 'hooks/useNavigation/useMobileNavigationLinks';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

import { MobileNavigationFooter } from './MobileNavigationFooter';
import { MobileNavigationHeader } from './MobileNavigationHeader';

const useStyles = makeStyles({
  headerOuterWrapper: [''],
  headerInnerWrapper: ['flex items-start justify-between'],
  navLinksGrid: ['grid grid-cols-3 gap-2 px-6'],
  navLinksWrapper: ['py-1'],
  solutionsHeaderWrapper: ['mt-4'],
  solutionsLinksOuterWrapper: ['divide-y divide-neutralBorder'],
  solutionsLinkWrapper: ['first:pt-0 py-3 px-3']
});

export const MobileNavigationContent = memo(() => {
  const styles = useStyles({});

  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');
  const [mobileNavEntry] = useContentfulUiComponentItems(publicNavbarContent, 'MobileNav');
  const links = useContentfulUiComponentItems(mobileNavEntry, 'NavbarLink');
  const headings = useContentfulUiComponentItems(mobileNavEntry, 'Heading');
  const solutionLinks = useMobileNavigationLinks();

  const navLinks = links.filter((item) => !item.icon);
  const [navigationHeading, solutionsHeading] = headings;

  return (
    <>
      <div className={styles.headerOuterWrapper}>
        <div className={styles.headerInnerWrapper}>
          <MobileNavigationHeader>{navigationHeading.primaryText}</MobileNavigationHeader>
        </div>
        <div className={styles.navLinksGrid}>
          {navLinks.map((component) => (
            <div key={component.sys.id} className={styles.navLinksWrapper}>
              <NavLink {...component} />
            </div>
          ))}
        </div>
        <MobileNavigationHeader className={styles.solutionsHeaderWrapper}>
          {solutionsHeading.primaryText}
        </MobileNavigationHeader>
      </div>
      <div className={styles.solutionsLinksOuterWrapper}>
        {solutionLinks.map((component) => (
          <div className={styles.solutionsLinkWrapper} key={component.sys.id}>
            <NavLink {...component} />
          </div>
        ))}
      </div>

      <RuntimeFeatureEnabled name="login">
        <MobileNavigationFooter />
      </RuntimeFeatureEnabled>
    </>
  );
});

MobileNavigationContent.displayName = 'MobileNavigationContent';
