import { PlaceMetaTypeSlug } from '@truckmap/common';
import { phoneValidationPattern } from 'components/common/Form';
import { InlineForm } from 'components/common/InlineForm';
import { TextInput, TextInputProps } from 'components/common/Input';
import { PlaceMetaProps } from 'components/Place/PlaceDetails';
import { usePlaceDetailsHiddenForm } from 'hooks/usePlaceDetails/usePlaceDetails';
import { useBreakpoints } from 'hooks/useUI/useBreakpoints';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PlaceDetailsEmptyValueProps } from '../Values/PlaceDetailsEmptyValue';
import { PlaceDetailsHiddenForm } from './PlaceDetailsHiddenForm';
import { usePlaceDetailsFormMutation } from './usePlaceDetailsFormMutation';

type PlaceDetailsStringFormProps = PlaceMetaProps & Pick<PlaceDetailsEmptyValueProps, 'showForm'>;

export const phoneNumberMask = '+1 (999) 999-9999';
export const phonePlaceholder = phoneNumberMask.replace(/9/g, '_');
export const phoneMask = { mask: phoneNumberMask, placeholder: phonePlaceholder };

export const MaskMap = new Map<
  PlaceMetaTypeSlug | string,
  Required<Pick<TextInputProps, 'mask' | 'placeholder'>>
>([
  [PlaceMetaTypeSlug.FAX_NUMBER, phoneMask],
  [PlaceMetaTypeSlug.APPOINTMENT_PHONE_NUMBER, phoneMask],
  [PlaceMetaTypeSlug.PHONE_NUMBER, phoneMask],
  [PlaceMetaTypeSlug.RECEIVING_PHONE_NUMBER, phoneMask]
]);

const PlaceDetailsStringForm = React.memo(({ meta, showForm }: PlaceDetailsStringFormProps) => (
  <PlaceDetailsHiddenForm id={meta.id} showForm={showForm}>
    {() => {
      const { toggleActiveForm, formId, formKey } = usePlaceDetailsHiddenForm(meta.id);
      const submitData = usePlaceDetailsFormMutation(meta);
      const onSubmit = (formData) => {
        toggleActiveForm();
        submitData(formData[meta.id]);
      };
      const { currentBreakpoint, breakpoints } = useBreakpoints();

      const maskProps = MaskMap.get(meta.slug) ?? {};

      return (
        <InlineForm
          id={formId}
          key={formKey}
          defaultPadding={false}
          isSmall={currentBreakpoint !== breakpoints.SM}
          fullWidth={currentBreakpoint === breakpoints.SM}
          onClose={toggleActiveForm}
          onSubmit={onSubmit}>
          {React.createElement(() => {
            const { register } = useFormContext();

            return (
              <TextInput
                name={meta.id}
                isSmall={currentBreakpoint !== breakpoints.SM}
                fullWidth={currentBreakpoint === breakpoints.SM}
                {...maskProps}
                {...register(meta.id, {
                  required: true,
                  minLength: 1,
                  pattern: !isEmpty(maskProps) ? phoneValidationPattern : null
                })}
              />
            );
          })}
        </InlineForm>
      );
    }}
  </PlaceDetailsHiddenForm>
));

PlaceDetailsStringForm.displayName = 'PlaceDetailsStringForm';

export { PlaceDetailsStringForm };
