'use client';
import { BodyText, HeadingText } from 'components/common/Typography';
import { Container } from 'components/layout/Container';
import { CheckInIcon } from 'icons/pinpoint/CheckInIcon';
import { EntranceIcon } from 'icons/pinpoint/EntranceIcon';
import { ExitIcon } from 'icons/pinpoint/ExitIcon';
import { GuardShackIcon } from 'icons/pinpoint/GuardShackIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { useMemo } from 'react';
import { UIComponentProps } from 'types/contentful';

import { TopSearchResultsItem } from './TopSearchResultsItem';

export const useStyles = makeStyles()({
  gridContainer: ['grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:gap-x-10', 'my-6 '],
  container: 'py-12 lg:py-18',
  icon: 'h-10 w-10',
  containerInner: ['mx-auto', 'px-4'],
  contentWrapper: 'flex flex-col space-y-2'
});

type IconRowItem = {
  icon: (className: string) => JSX.Element;
};

export const icons: IconRowItem[] = [
  {
    icon: (className: string) => <EntranceIcon className={className} />
  },
  {
    icon: (className: string) => <ExitIcon className={className} />
  },
  {
    icon: (className: string) => <CheckInIcon className={className} />
  },
  { icon: (className: string) => <GuardShackIcon className={className} /> }
];

const TopSearchResultsSection = React.memo(({ entry }: UIComponentProps) => {
  const styles = useStyles();
  const { items, richTextSections } = entry;
  const [heading, subheading] = richTextSections;

  const topSearchResultsItems = useMemo(
    () =>
      items.map((item, index) => {
        return (
          <TopSearchResultsItem
            key={`top_search_results_item_${index}`}
            href={item.primaryLink.url}
            icon={icons[index]?.icon(styles.icon)}
            title={item.primaryText}
            description={item.secondaryText}
          />
        );
      }),
    [items]
  );

  return (
    <Container hasBackgroundColor className={styles.container}>
      <div className={styles.containerInner}>
        <div className={styles.contentWrapper}>
          {heading && (
            <HeadingText as="h3" size="MD">
              {heading.primaryText}
            </HeadingText>
          )}
          {subheading && <BodyText large>{subheading.primaryText}</BodyText>}
        </div>
        {items && <div className={styles.gridContainer}>{topSearchResultsItems}</div>}
      </div>
    </Container>
  );
});

TopSearchResultsSection.displayName = 'SearchResultsSection';

export { TopSearchResultsSection };
