import { RadioGroup } from 'components/common/Input/SelectInputGroup';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { sortHubSpotFormOptions } from 'lib/hubspot/sortHubSpotFormOptions';
import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export const HubSpotRadioField = memo((field: HubSpotFormFieldType) => {
  const { watch } = useFormContext();
  const currentValue = watch(field.name);
  const sortedOptions = useMemo(() => sortHubSpotFormOptions(field.options), [field.options]);

  return (
    <RadioGroup
      name={field.name}
      options={sortedOptions.map(({ ...props }) => ({
        ...props,
        defaultChecked: currentValue === props.value
      }))}
    />
  );
});

HubSpotRadioField.displayName = 'HubSpotRadioField';
