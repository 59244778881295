'use client';
import cx from 'clsx';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import { reverseUrl } from 'lib/reverseUrl';
import { PublicURLModel, URL_PARAMS_GET_APP_PLATFORM } from 'models/PublicURLModel';

import { ExternalLink } from '../ExternalLink';
import { AppStoreReview } from './AppStoreReview';

const useStyles = makeStyles({
  wrapper: 'mt-6 flex flex-row',
  appStoreWrapper:
    'hover:bg-secondaryBackgroundHover transition-all rounded py-4 px-4 cursor-pointer group',
  paddingRight: 'pr-2 md:pr-4',
  paddingLeft: 'pl-2 md:pl-4',
  marginLeft: 'ml-4 focus-default'
});

export const AppStoreReviews = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <ExternalLink
        className="focus-default"
        aria-label={t('DOWNLOAD_IOS_APP')}
        href={reverseUrl<URL_PARAMS_GET_APP_PLATFORM>(PublicURLModel.GET_APP_PLATFORM, {
          platform: 'ios'
        })}>
        <div className={cx(styles.paddingRight, styles.appStoreWrapper)}>
          <AppStoreReview provider="apple" />
        </div>
      </ExternalLink>
      <ExternalLink
        aria-label={t('DOWNLOAD_ANDROID_APP')}
        href={reverseUrl<URL_PARAMS_GET_APP_PLATFORM>(PublicURLModel.GET_APP_PLATFORM, {
          platform: 'android'
        })}
        className={styles.marginLeft}>
        <div className={cx(styles.paddingLeft, styles.appStoreWrapper)}>
          <AppStoreReview provider="android" />
        </div>
      </ExternalLink>
    </div>
  );
};
