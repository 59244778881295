import React, { memo, useCallback, useState } from 'react';

import { DropdownList } from '../../DropdownList';
import { ListOption } from '../../DropdownList/DropdownListOptions';
import { MetaText } from '../../Typography';
import { formatMinute, minutesOptions, TimePickerProps, useStyles } from './TimePicker';

const MinutePicker = memo(({ showMinutes = true }: TimePickerProps) => {
  const styles = useStyles();
  const [minutesValue, setMinutesValue] = useState<ListOption>(null);

  const createMinutesOptions = useCallback((): ListOption[] => {
    return minutesOptions.map((item, index) => ({
      value: item,
      customSelected: minutesValue?.value === item,
      itemLabel: formatMinute(item),
      buttonLabel: formatMinute(item),
      initialValue: index === 0
    }));
  }, [minutesValue, minutesOptions, formatMinute]);

  return (
    showMinutes && (
      <>
        <MetaText bold className={styles.marginAuto}>
          :
        </MetaText>
        <DropdownList
          isSmall
          drawerClassName={styles.drawerClassName}
          options={createMinutesOptions()}
          onChange={setMinutesValue}
        />
      </>
    )
  );
});

MinutePicker.displayName = 'MinutePicker';

export { MinutePicker };
