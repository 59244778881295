'use client';
import cx from 'clsx';
import { Button } from 'components/common/Button';
import { BodyText, HeadingText } from 'components/common/Typography';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import { usePageContext } from 'components/Page/PageContext';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { RoadWorkIcon } from 'icons/roadSigns/RoadWorkIcon';
import { UTurnIcon } from 'icons/roadSigns/UTurnIcon';
import { makeStyles } from 'lib/makeStyles';
import { useRouter } from 'next/navigation';
import React from 'react';
import { ErrorCodes } from 'types/common';
import { UIComponentProps } from 'types/contentful';

import { useStyles as useErrorPageHeroContentStyles } from './ErrorPageHeroBackground';

type Props = Pick<UIComponentProps, 'entry'>;

const useStyles = makeStyles({
  outerWrapper: 'flex relative bg-neutralBackgroundSoft px-6 max-w-4xl',
  icon: 'w-[20vw] min-w-[120px] max-w-[200px] h-auto',
  contentOuterWrapper: 'absolute z-2 w-full',
  contentWrapper: ['w-full px-6', 'flex flex-col items-center justify-center'],
  centerText: 'text-center',
  buttonRow: 'flex flex-row space-x-3 my-4',
  headingTwo: 'block my-2'
});

const defaultButtonHref = '/';

export const ErrorPageHeroContent = React.memo(({ entry }: Props) => {
  const { statusCode = ErrorCodes.ERROR } = usePageContext();
  const styles = useStyles();
  const { richTextSections, items } = entry;
  const { t } = useTranslation();
  const router = useRouter();
  const primaryButton = items.find((item) => item.type === 'Button');
  const [heading, subheading] = richTextSections;
  const errorPageHeroStyles = useErrorPageHeroContentStyles({
    containerClassName: styles.contentOuterWrapper
  });

  return (
    <div className={errorPageHeroStyles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.icon}>
          {statusCode === ErrorCodes.NOT_FOUND ? (
            <UTurnIcon className={styles.icon} />
          ) : (
            <RoadWorkIcon className={styles.icon} />
          )}
        </div>
        {heading && (
          <HeadingText size="MD" className={cx(styles.headingTwo, styles.centerText)}>
            {heading.primaryText}
          </HeadingText>
        )}
        {subheading && (
          <BodyText large className={styles.centerText}>
            {subheading.primaryText}
          </BodyText>
        )}
        <div className={styles.buttonRow}>
          <Button as="div" dark onClick={() => router.back()}>
            {t('GO_BACK')}
          </Button>
          {primaryButton && (
            <NonPrefetchLink href={primaryButton.primaryLink?.url ?? defaultButtonHref}>
              <Button as="div" isPrimary>
                {primaryButton.primaryText ?? t('CONTINUE')}
              </Button>
            </NonPrefetchLink>
          )}
        </div>
      </div>
    </div>
  );
});

ErrorPageHeroContent.displayName = 'ErrorPageHeroContent';
