export const submitHubSpotFormData = async ({ portalId, formId, fields }) => {
  try {
    const response = await fetch('/api/hubspotForms/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ portalId, formId, fields })
    });

    if (!response.ok) {
      throw new Error(`Error submitting form data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
