import { useMobilePublicSearchBreakpoint } from 'hooks/usePublicSearchInput/useMobilePublicSearchBreakpoint';
import { makeStyles } from 'lib/makeStyles';

export const useStyles = makeStyles({
  button: ({ className }) => [
    'absolute top-1/2 -mt-2',
    'text-neutralText text-sm',
    'focus-within:focus-default',
    className
  ]
});

type SearchIconWrapperProps = ParentClassNameProp &
  ClickEventProp &
  Pick<HTMLButtonElement, 'title'> &
  ChildrenProp;

const SearchIconWrapper: React.FC<SearchIconWrapperProps> = ({
  children,
  className,
  onClick,
  title
}) => {
  const styles = useStyles({ className });
  const { isMobileSearchBreakpoint } = useMobilePublicSearchBreakpoint();

  if (isMobileSearchBreakpoint) {
    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
        onClick={onClick}
        title={title}
        aria-label={title}
        className={styles.button}>
        {children}
      </div>
    );
  }

  return <div className={styles.button}>{children}</div>;
};

SearchIconWrapper.displayName = 'SearchIconWrapper';

export { SearchIconWrapper };
