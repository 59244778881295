import { AssetIcon } from 'components/common/AssetIcon';
import { CloseButton } from 'components/common/Button';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalHeading } from 'components/common/Modal/ModalContent';
import { HubSpotFormButtons } from 'components/tenstreet/HubSpotFormModal/HubSpotFormButtons';
import {
  HubSpotFormFields,
  HubSpotFormFieldsType
} from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { HubSpotFormSuccess } from 'components/tenstreet/HubSpotFormModal/HubSpotFormSuccess';
import { useTranslation } from 'hooks/useContentful';
import { makeStyles } from 'lib/makeStyles';
import { memo, useCallback, useMemo } from 'react';
import { useFormState } from 'react-hook-form';
import { useWindowSize } from 'react-use';
import { useFlexColumnLayoutStyles } from 'styles';

// Offsets modal padding
const HUBSPOT_FORM_CONTENT_HEIGHT_OFFSET = 26;

type HubSpotFormContentProps = {
  loading?: boolean;
  onClose: GenericHandler;
} & HubSpotFormFieldsType;

const useStyles = makeStyles({
  formContent: ['overflow-y-auto', 'px-3'],
  loading: ['h-[400px]', 'flex justify-center items-center'],
  closeButton: ['absolute top-1 right-2'],
  modalHeading: ['w-full', '-mt-2', 'px-3']
});

export const HubSpotFormContent = memo(
  ({ loading, formFields, onClose }: HubSpotFormContentProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const flexLayoutStyles = useFlexColumnLayoutStyles({
      flexGrowClassName: styles.formContent
    });
    const { isSubmitSuccessful } = useFormState();

    const onCloseModal = useCallback(
      () => onClose({ enablePingEvent: !isSubmitSuccessful }),
      [isSubmitSuccessful, onClose]
    );

    const { height: windowHeight } = useWindowSize();
    const containerMaxHeight = useMemo(
      () => windowHeight - HUBSPOT_FORM_CONTENT_HEIGHT_OFFSET,
      [windowHeight]
    );

    return (
      <>
        <div className={flexLayoutStyles.flexContainer} style={{ maxHeight: containerMaxHeight }}>
          <div className={flexLayoutStyles.flexShrink}>
            <ModalHeading
              className={styles.modalHeading}
              heading={t('TENSTREET_REQUEST_DEMO_MODAL_HEADING')}
              subheading={t('TENSTREET_REQUEST_DEMO_MODAL_SUBHEADING')}
              prepend={<AssetIcon type="app-category" slug="questions" size="SM" />}
            />
          </div>
          <div className={flexLayoutStyles.flexGrow}>
            {loading ? (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            ) : isSubmitSuccessful ? (
              <HubSpotFormSuccess />
            ) : (
              <HubSpotFormFields formFields={formFields} />
            )}
          </div>
          <div className={flexLayoutStyles.flexShrink}>
            <HubSpotFormButtons onCancel={onCloseModal} />
          </div>
        </div>
        <CloseButton onClose={onCloseModal} className={styles.closeButton} />
      </>
    );
  }
);

HubSpotFormContent.displayName = 'HubSpotFormContent';
