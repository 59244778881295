'use client';
import { BackButton } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { Modal } from 'components/common/Modal';
import { ModalTitleWithIcon } from 'components/common/Modal/ModalContent';
import { ModalFooter } from 'components/common/Modal/ModalSelectGroup/ModalFooter';
import { SkeletonGrid } from 'components/common/Skeleton/SkeletonGrid';
import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelPadding } from 'components/layout/Panel/PanelPadding';
import { TruckOptionsContent } from 'components/TruckOptions/TruckOptionsContent';
import { useTruckOptionsModal } from 'hooks/useTruckOptions/useTruckOptionsModal';
import { makeStyles } from 'lib/makeStyles';
import React, { Suspense } from 'react';

const useTruckOptionsModalStyles = makeStyles({
  icon: ['w-5 h-5', 'mr-2', 'shrink-0', 'bg-neutralIcon bg-no-repeat'],
  backButton: ['sm:hidden'],
  closeButton: ['absolute top-1 right-2.5']
});

export const TruckOptionsModal: React.ComponentType = () => {
  const {
    isMobileVersion,
    activeModalTitle,
    handleBackButton,
    handleSubmit,
    handleCancel,
    modalOpen,
    activeOption,
    shouldDisableSubmit
  } = useTruckOptionsModal();
  const styles = useTruckOptionsModalStyles();

  const modalTitleText = (isMobileVersion && activeModalTitle) || (
    <Translation textKey="TRUCK_SETTINGS_BUTTON" />
  );

  return (
    <Modal
      title={
        isMobileVersion && activeModalTitle ? (
          <PanelFlex paddingY={false}>
            {activeOption && (
              <BackButton className={styles.backButton} onClick={handleBackButton} circle="SM" />
            )}
            <MetaText as="h2" bold>
              {modalTitleText}
            </MetaText>
          </PanelFlex>
        ) : (
          <ModalTitleWithIcon
            title={modalTitleText}
            icon={<HeroIcon className={styles.icon} type="solid-mini" name="truck" />}
          />
        )
      }
      as="form"
      onSubmit={handleSubmit}
      disableOverflowY
      open={modalOpen}
      onClose={handleCancel}
      showClose={true}
      footer={<ModalFooter shouldDisableSubmit={shouldDisableSubmit} onReset={handleCancel} />}>
      <>
        {/* we only load/request all options when modal is open, this optimization is needed since this component is used in all pages */}
        <Suspense
          fallback={
            <PanelPadding>
              <SkeletonGrid rows={14} columns={2} />
            </PanelPadding>
          }>
          <OpacityTransition show={modalOpen}>
            <TruckOptionsContent activeOption={activeOption} />
          </OpacityTransition>
        </Suspense>
      </>
    </Modal>
  );
};

TruckOptionsModal.defaultProps = {
  size: 'sm'
};

TruckOptionsModal.displayName = 'TruckOptionsModal';
