import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SmallText } from '../../Typography';
import { Props as RadioGroupProps } from './RadioGroup';

export type Props = {
  title: string;
  col?: number;
} & ChildrenProp &
  Pick<RadioGroupProps, 'hasCustomGroup'>;

export const useSelectGroupGridStyles = makeStyles<Pick<Props, 'col'> & ParentClassNameProp>()({
  grid: (props) => [
    {
      [`sm:grid sm:grid-cols-${props.col?.toString() ?? '1'}`]: props.col,
      'gap-x-1 gap-y-1': props.col
    },
    // Avoids focus ring getting cut off
    'px-[2px]',
    'w-full',
    props.className
  ]
});

const useStyles = makeStyles({
  root: ({ hasCustomGroup }) => ['w-full', { 'mb-3': hasCustomGroup }],
  title: ['text-paragraph-700 mb-1']
});

export const SelectGroupSection: React.FC<Props> = ({
  children,
  col,
  title,
  hasCustomGroup = false
}) => {
  const styles = useStyles({ col, hasCustomGroup });
  const gridStyles = useSelectGroupGridStyles({ col });
  return (
    <div className={styles.root}>
      {hasCustomGroup && (
        <SmallText bold className={styles.title}>
          {title}
        </SmallText>
      )}
      <div className={gridStyles.grid}>{children}</div>
    </div>
  );
};

SelectGroupSection.displayName = 'SelectGroupSection';
