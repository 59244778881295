import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const ExitIcon = React.memo((props: GlyphProps) => (
  <Glyph width={40} height={40} {...props}>
    <g filter="url(#filter0_d_exit_icon)">
      <circle r="16" transform="matrix(1 0 0 -1 20 19)" fill="#D52A53" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2975 26.7109C19.2958 26.7098 19.2943 26.7085 19.2929 26.7071L14.2929 21.7071C13.9024 21.3166 13.9024 20.6834 14.2929 20.2929C14.6834 19.9024 15.3166 19.9024 15.7071 20.2929L18.1464 22.7322C18.4614 23.0472 19 22.8241 19 22.3787V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V22.3787C21 22.8241 21.5386 23.0472 21.8536 22.7322L24.2929 20.2929C24.6834 19.9024 25.3166 19.9024 25.7071 20.2929C26.0976 20.6834 26.0976 21.3166 25.7071 21.7071L20.7092 26.705L20.7071 26.7071L20.705 26.7092C20.5242 26.8889 20.2751 27 20 27C19.8644 27 19.7351 26.973 19.6172 26.9241"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_exit_icon"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

ExitIcon.displayName = 'ExitIcon';

export { ExitIcon };
