import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { DropdownIcon } from 'icons/monochrome/DropdownIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { ReactNode } from 'react';

import { MetaText } from '../Typography';
import { DropdownIconTransition } from './DropdownIconTransition';

type DropdownIconButtonProps = {
  label?: string | ReactNode;
  open: boolean;
} & Pick<PanelItemProps, 'as'> &
  ChildrenProp;

const useStyles = makeStyles({
  panel: ['w-full h-full'],
  buttonInner: ['w-full', 'flex', 'justify-between', 'content-center'],
  buttonLabel: ['w-full whitespace-nowrap', 'grow', 'justify-self-start'],
  iconWrapper: ['ml-1', 'justify-self-end']
});

export const DropdownIconButton: React.FC<DropdownIconButtonProps> = ({
  children,
  label,
  open,
  as
}) => {
  const styles = useStyles();

  return (
    <PanelFlex as={as} className={styles.panel} fullWidth paddingX="SM" justify="between">
      {typeof label === 'string' ? (
        <MetaText as="label" enableHover="group" className="px-1">
          {label}
        </MetaText>
      ) : (
        label
      )}
      {children}
      <DropdownIconTransition open={open}>
        <DropdownIcon />
      </DropdownIconTransition>
    </PanelFlex>
  );
};

DropdownIconButton.displayName = 'DropdownIconButton';
