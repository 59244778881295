'use client';
import { Asset } from 'components/contentful/Asset/Asset';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { useMobileBreakpoint } from 'hooks/useUI/useMobileBreakpoint';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';
import { SerializedComponentItem } from 'types/contentful';

const useStyles = makeStyles()({
  imageWrapper: ['h-[240px] md:h-auto overflow-hidden'],
  image: ['w-[400px] md:w-[280px] mt-4 md:mt-0'],
  fadeEffect: [
    'absolute md:hidden bottom-0',
    'w-full h-[100px]',
    'bg-gradient-to-t from-10% from-white'
  ]
});

export const FeatureSectionImage = memo(({ image }: { image: SerializedComponentItem }) => {
  const isMobileBreakpoint = useMobileBreakpoint();
  const styles = useStyles();

  return (
    <PanelFlex
      relative
      column
      fullWidth
      justify={isMobileBreakpoint ? 'start' : 'center'}
      align="center"
      className={styles.imageWrapper}>
      <Asset entry={image} className={styles.image} />
      <div className={styles.fadeEffect} />
    </PanelFlex>
  );
});

FeatureSectionImage.displayName = 'FeatureSectionImage';
