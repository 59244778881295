'use client';
import { useCustomGroupInputType } from 'hooks/useInput/useCustomGroupInputType';
import { makeStyles } from 'lib/makeStyles';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioGroupItem } from './RadioGroupItem';
import { SelectGroupSection } from './SelectGroupSection';
import { SelectGroupTitle } from './SelectGroupTitle';

export type InputOptionType = {
  disabled?: boolean;
  label?: JSX.Element | string;
  customGroup?: string;
  defaultChecked?: boolean;
} & Omit<JSX.IntrinsicElements['input'], 'name'> &
  Required<Pick<JSX.IntrinsicElements['input'], 'value'>>;

export type SelectGroupType = {
  name: string;
  title?: string;
  truncate?: number;
  hasSelectedSticky?: boolean;
  borderless?: boolean;
  col?: number;
  optionClassName?: string;
};

export type Props = {
  options: InputOptionType[];
  required?: boolean;
  hasCustomGroup?: boolean;
} & SelectGroupType &
  Required<Pick<JSX.IntrinsicElements['input'], 'name'>> &
  ParentClassNameProp;

export const useStyles = makeStyles({
  wrapper: (props: Props) => ['w-full', 'py-1', props?.className]
});

export const RadioGroup = ({
  title,
  options,
  hasSelectedSticky,
  hasCustomGroup,
  optionClassName,
  name,
  borderless = true,
  col,
  required,
  className,
  ...inputProps
}: Props) => {
  const customGroupOptions = useCustomGroupInputType(options, 'customGroup');

  const styles = useStyles({ col, hasCustomGroup, className });
  const {
    control,
    getValues,
    reset,
    formState: { isDirty }
  } = useFormContext();

  useEffect(() => {
    if (required) {
      const values = getValues();
      const currentValue = values[name];
      if (isDirty && !options.find((option) => option.value === currentValue)) {
        reset({ [name]: null }, { keepDirty: false });
      }
    }
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      shouldUnregister
      render={({ field: { onChange, value: currentValue } }) => (
        <div className={styles.wrapper} onChange={onChange}>
          {title && <SelectGroupTitle title={title} />}
          {Object.keys(customGroupOptions).map((key, index) => {
            return (
              <SelectGroupSection key={index} title={key} col={col} hasCustomGroup={hasCustomGroup}>
                {customGroupOptions[key].map((option, index) => {
                  return (
                    <RadioGroupItem
                      hasSelectedSticky={hasSelectedSticky}
                      optionClassName={optionClassName}
                      key={`${name}_${index}_${option.value}`}
                      borderless={borderless}
                      currentValue={
                        currentValue ??
                        options.find((option) => option.defaultChecked === true)?.value
                      }
                      name={name}
                      option={option}
                      {...inputProps}
                    />
                  );
                })}
              </SelectGroupSection>
            );
          })}
        </div>
      )}
    />
  );
};

RadioGroup.displayName = 'RadioGroup';
