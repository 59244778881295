import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const EntranceIcon = React.memo((props: GlyphProps) => (
  <Glyph width={40} height={40} {...props}>
    <g filter="url(#prefix__filter0_d)">
      <circle cx={20} cy={19} r={16} fill="#3796FE" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.297 11.29a.028.028 0 00-.004.003l-5 5a1 1 0 001.414 1.414l2.44-2.44a.5.5 0 01.853.354V26a1 1 0 102 0V15.621a.5.5 0 01.854-.353l2.439 2.44a1 1 0 001.414-1.415l-4.998-4.998-.002-.002-.002-.002a.997.997 0 00-1.088-.215"
      fill="#fff"
    />
    <defs>
      <filter
        id="prefix__filter0_d"
        x={0}
        y={0}
        width={40}
        height={40}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

EntranceIcon.displayName = 'EntranceIcon';

export { EntranceIcon };
