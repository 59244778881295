import dayjs from 'dayjs';

export const formatHubSpotFormFields = (data) =>
  Object.keys(data).map((key) => {
    let value = data[key];

    if (Array.isArray(value)) {
      value = value.filter((val) => typeof val === 'string').join(';');
    } else if (typeof value === 'string' && dayjs(value).isValid() && value.includes('T')) {
      // Format the date to 'YYYY-MM-DD'
      value = dayjs(value).format('YYYY-MM-DD');
    }

    return {
      name: key,
      value: value
    };
  });
