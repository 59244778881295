import { BodyText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { makeStyles } from 'lib/makeStyles';
import { memo, useMemo } from 'react';
import { SerializedComponentItem } from 'types/contentful';

const useStyles = makeStyles()({
  decorator: ['text-2xl font-semibold text-brightBlue-600'],
  bodyText: ['max-w-2xl', 'mb-4', 'h-[120px] md:h-[100px]']
});

export const TenstreetCarouselItemContent = memo(({ item }: { item: SerializedComponentItem }) => {
  const styles = useStyles();

  const [formattedTitle, formattedSubtitle] = useMemo(() => {
    const [title, subtitle] = item.primaryText.split('-').map((part) => part.trim());

    return [title, subtitle];
  }, []);

  const content = item.secondaryText;

  return (
    <PanelFlex column spacing="LG">
      <PanelFlex row align="center" justify="center">
        <BodyText color="primary">{formattedTitle}</BodyText>
        <span className={styles.decorator}>/</span>
        <BodyText color="primary">{formattedSubtitle}</BodyText>
      </PanelFlex>
      {content && (
        <div className={styles.bodyText}>
          <BodyText color="primary" align="center">
            &quot;{content}&quot;
          </BodyText>
        </div>
      )}
    </PanelFlex>
  );
});

TenstreetCarouselItemContent.displayName = 'TenstreetCarouselItemContent';
