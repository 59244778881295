import { InputLabel } from 'components/common/Input/InputLabel';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import React from 'react';

export const HubSpotFormFieldLabel = React.memo(
  ({ field, children }: { field: HubSpotFormFieldType } & ChildrenProp) => {
    return (
      <InputLabel
        key={field?.name}
        label={field?.label}
        required={field?.required}
        as={field?.fieldType === 'multiple_checkboxes' ? 'div' : 'label'}
        hideLabel={field?.fieldType === 'single_checkbox'}
        htmlFor={field?.name}>
        {children}
      </InputLabel>
    );
  }
);

HubSpotFormFieldLabel.displayName = 'HubSpotFormFieldLabel';
