import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const UTurnIcon = React.memo((props: GlyphProps) => (
  <Glyph width={39} height={41} {...props}>
    <path
      d="M3.61597 17.5081L17.5081 3.61597C17.9045 3.22147 18.441 3 19.0003 3C19.5596 3 20.0961 3.22147 20.4926 3.61597L34.381 17.5081C34.5772 17.7039 34.7329 17.9365 34.8391 18.1926C34.9453 18.4486 35 18.7231 35 19.0003C35 19.2775 34.9453 19.552 34.8391 19.8081C34.7329 20.0641 34.5772 20.2967 34.381 20.4926L20.4926 34.381C20.2967 34.5772 20.0641 34.7329 19.8081 34.8391C19.552 34.9453 19.2775 35 19.0003 35C18.7231 35 18.4486 34.9453 18.1926 34.8391C17.9365 34.7329 17.7039 34.5772 17.5081 34.381L3.61597 20.4926C3.22147 20.0961 3 19.5596 3 19.0003C3 18.441 3.22147 17.9045 3.61597 17.5081Z"
      fill="#FFD600"
    />
    <path
      d="M3.61597 17.5081L17.5081 3.61597C17.9045 3.22147 18.441 3 19.0003 3C19.5596 3 20.0961 3.22147 20.4926 3.61597L34.381 17.5081C34.5772 17.7039 34.7329 17.9365 34.8391 18.1926C34.9453 18.4486 35 18.7231 35 19.0003C35 19.2775 34.9453 19.552 34.8391 19.8081C34.7329 20.0641 34.5772 20.2967 34.381 20.4926L20.4926 34.381C20.2967 34.5772 20.0641 34.7329 19.8081 34.8391C19.552 34.9453 19.2775 35 19.0003 35C18.7231 35 18.4486 34.9453 18.1926 34.8391C17.9365 34.7329 17.7039 34.5772 17.5081 34.381L3.61597 20.4926C3.22147 20.0961 3 19.5596 3 19.0003C3 18.441 3.22147 17.9045 3.61597 17.5081V17.5081Z"
      stroke="black"
      strokeWidth="1.9"
    />
    <path
      d="M10.2668 20.8592V21.0242C10.3365 21.1342 10.3988 21.2515 10.4758 21.3542L14.2009 26.2819L15.0405 27.3818L15.1725 27.2131L17.2367 24.4376C18.0287 23.3707 18.8243 22.3074 19.6089 21.2368C19.6964 21.1207 19.7428 20.9789 19.7409 20.8335C19.735 20.7641 19.7126 20.6971 19.6757 20.638C19.6388 20.5788 19.5884 20.5294 19.5286 20.4936C19.4688 20.4578 19.4013 20.4367 19.3318 20.432C19.2623 20.4274 19.1926 20.4393 19.1286 20.4669L17.0607 21.1232C16.9948 21.1452 16.9288 21.1598 16.8444 21.1818V20.9655C16.8444 20.0049 16.8444 19.0443 16.8444 18.0837C16.8444 17.2881 16.8188 16.4888 16.8774 15.6932C16.9138 15.3166 17.0312 14.9523 17.2216 14.6254C17.4121 14.2985 17.671 14.0166 17.9807 13.7993C18.2904 13.5819 18.6435 13.4341 19.0157 13.3661C19.3878 13.2981 19.7703 13.3115 20.1369 13.4053C20.717 13.5517 21.2306 13.8901 21.5941 14.3654C21.9575 14.8407 22.1495 15.4249 22.1388 16.0232C22.1388 19.2007 22.1388 22.3783 22.1388 25.5559C22.1419 25.7988 22.1928 26.0387 22.2887 26.2619C22.3845 26.4852 22.5234 26.6873 22.6974 26.8569C22.8714 27.0264 23.077 27.16 23.3026 27.2501C23.5282 27.3402 23.7694 27.3849 24.0123 27.3818C24.2552 27.3787 24.4951 27.3277 24.7183 27.2319C24.9416 27.136 25.1437 26.9971 25.3133 26.8232C25.4828 26.6492 25.6164 26.4435 25.7065 26.2179C25.7966 25.9923 25.8413 25.7511 25.8382 25.5082C25.8382 22.3453 25.8382 19.18 25.8382 16.0122C25.8352 15.5519 25.7898 15.0929 25.7025 14.6409C25.375 13.1024 24.4881 11.7401 23.2137 10.8181C21.9392 9.89606 20.3679 9.47986 18.8041 9.65011C17.2403 9.82037 15.7954 10.565 14.7492 11.7397C13.703 12.9143 13.1301 14.4355 13.1413 16.0085V21.2038L12.8993 21.1415L11.0295 20.5549C10.6115 20.4229 10.4795 20.4742 10.2668 20.8592Z"
      fill="black"
    />
  </Glyph>
));

UTurnIcon.displayName = 'UTurnIcon';

export { UTurnIcon };
