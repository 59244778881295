import { PanelBasic } from 'components/layout/Panel/PanelBasic';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { PopoverOpenTransition } from '../../PopoverForm/PopoverOpenTransition';
import { CalendarProps } from './Calendar';

type CalendarViewProps = Pick<CalendarProps, 'show' | 'prepend' | 'append'> & ChildrenProp;

const useStyles = makeStyles()({
  panelItem: 'mt-2 bg-white',
  calendar: 'py-1 relative',
  monthYear: 'absolute left-[40px] w-[198px] h-[30px] top-0 text-center py-2',
  dowHeader: 'absolute left-0 top-[28px] w-[277px] text-center py-2'
});

const CalendarView: React.FC<CalendarViewProps> = memo(({ children, show, append, prepend }) => {
  const styles = useStyles();
  return (
    <PopoverOpenTransition open={show}>
      <PanelBasic fullWidth type={'popover'} className={styles.panelItem}>
        {prepend && prepend}
        <div className={styles.calendar}>{children}</div>
        {append && append}
      </PanelBasic>
    </PopoverOpenTransition>
  );
});

CalendarView.displayName = 'CalendarView';

export { CalendarView };
