import { atom } from 'recoil';

export type HubSpotFormAtom = {
  show: boolean;
  hasError?: boolean;
  errorMessage?: string;
  formId?: string;
  portalId?: string;
};

export const hubspotFormAtom = atom<HubSpotFormAtom>({
  key: 'hubspotFormAtom',
  default: {
    show: false,
    hasError: false,
    errorMessage: null,
    portalId: null,
    formId: null
  }
});
