'use client';
import { Form, FormSubmitButton, Props as FormProps } from 'components/common/Form';
import { makeStyles } from 'lib/makeStyles';
import React, { memo, useRef } from 'react';
import { useFormState } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { CloseButton } from '../Button';

export type Props = {
  isSmall?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (e?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (e?: any) => void;
  hasClickAway?: boolean;
} & FormProps;

type StyleProps = Pick<FormProps, 'fullWidth'>;

const useStyles = makeStyles<StyleProps>()({
  root: ({ fullWidth }: StyleProps) => [
    {
      'w-full overflow-hidden flex': fullWidth
    }
  ]
});

const InlineForm: React.FC<Props> = memo(
  ({ children, hasClickAway, onClose, isSmall, onSubmit, fullWidth, ...formProps }) => {
    const styles = useStyles({ fullWidth });
    const ref = useRef();

    hasClickAway &&
      useClickAway(ref, () => {
        open && onClose();
      });

    return (
      <div ref={ref} className={styles.root}>
        <Form inline onSubmit={onSubmit} fullWidth={fullWidth} {...formProps}>
          {children}
          {React.createElement(() => {
            const { isValid } = useFormState();

            return <FormSubmitButton disabled={!isValid} isSmall={isSmall} />;
          })}
          {onClose && <CloseButton onClose={onClose} hasTabClose defaultPadding={false} />}
        </Form>
      </div>
    );
  }
);

InlineForm.defaultProps = {
  hasClickAway: false
};

InlineForm.displayName = 'InlineForm';

export { InlineForm };
