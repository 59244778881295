import { getStaticOptimizedImageUrl, StaticImageConfig } from 'lib/image/getImageUrl';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type StaticImageProps = {
  alt?: string;
  ariaHidden?: boolean;
} & StaticImageConfig &
  ParentClassNameProp;

const useStaticImageStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => [props.className]
});

export const StaticImage: React.FC<StaticImageProps> = React.memo(
  ({ alt, src, className, type, ariaHidden, ...optimizerProps }) => {
    const styles = useStaticImageStyles({ className });

    return (
      <picture className={styles.root}>
        <img
          src={getStaticOptimizedImageUrl({ src, type, format: 'png', ...optimizerProps })}
          width={optimizerProps.width}
          height={optimizerProps.height}
          alt={!ariaHidden ? alt : ''}
          aria-hidden={ariaHidden}
        />
      </picture>
    );
  }
);

StaticImage.defaultProps = {
  quality: 80,
  alt: '',
  type: 'local',
  ariaHidden: true
};

StaticImage.displayName = 'StaticImage';
