'use client';
import { NavbarItems } from 'components/PublicNavigation/NavbarItems';
import {
  useContentfulUiComponentFromQuery,
  useContentfulUiComponentItems
} from 'hooks/useContentful';
import { memo } from 'react';

export const NavbarItemsClient = memo(() => {
  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');

  if (!publicNavbarContent) {
    return <></>;
  }

  const navbarLinks = useContentfulUiComponentItems(publicNavbarContent, 'NavbarLink');

  return <NavbarItems items={navbarLinks} />;
});

NavbarItemsClient.displayName = 'NavbarItemsClient';
