import clsx from 'clsx';
import { useSliderPaginationContext } from 'hooks/useSliderPagination';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful';

const useStyles = makeStyles<ParentClassNameProp>()({
  wrapper: (props) => [
    'grid grid-flow-col grid-cols-4 gap-5',
    'bg-secondaryBackground',
    props.className
  ],
  button: 'h-1 border-none focus-default',
  blueBg: 'bg-tmBlue',
  grayBg: 'bg-gray-050'
});

const SliderNav = React.memo(({ className }: ParentClassNameProp) => {
  const { currentPage, slideTo, items } = useSliderPaginationContext();
  const styles = useStyles({ className });

  return (
    <div className={styles.wrapper}>
      {(items as SerializedComponentItem[]).map((entry, index) => {
        const pageIndex = index + 1;
        const isCurrentPage = pageIndex === currentPage;
        return (
          <button
            key={`slider-nav-${entry.id}`}
            aria-label={entry.primaryText}
            className={clsx(
              styles.button,
              isCurrentPage && styles.blueBg,
              !isCurrentPage && styles.grayBg
            )}
            onClick={() => slideTo(pageIndex)}
          />
        );
      })}
    </div>
  );
});

SliderNav.displayName = 'SliderNav';

export { SliderNav };
