'use client';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { AndroidIcon } from 'icons/monochrome/AndroidIcon';
import { AppleIcon } from 'icons/monochrome/AppleIcon';
import { StarIcon } from 'icons/monochrome/StarIcon';
import { makeStyles } from 'lib/makeStyles';
import { useParams } from 'next/navigation';
import { useMemo } from 'react';

type Props = {
  provider: 'apple' | 'android';
  count?: number;
};

const useStyles = makeStyles({
  outerWrapper: ['flex flex-row'],
  screenReaderOnly: ['sr-only'],
  flexWrapper: ['flex flex-col justify-start items-left'],
  platformIcon: ['mr-2 text-base text-neutralIcon md:text-3xl md:mr-3 md:text-primaryIcon'],
  platformTitle: ['text-base font-semibold text-primaryText flex'],
  reviewsCount: [
    'font-normal text-secondaryText text-xs flex ml-2 group-hover:text-secondaryText transition-all'
  ],
  starsOuterWrapper: ['flex items-center mt-1'],
  starsInnerWrapper: ['shrink-0 flex'],
  starIcon: ['text-base text-primaryIcon group-hover:text-orange-600 transition-all'],
  textWrapper: ['flex flex-wrap items-baseline']
});

export const AppStoreReviewContent: React.FC<Props> = (props) => {
  const title = props.provider === 'apple' ? 'iOS' : 'Android';
  const params = useParams();
  const { t } = useTranslation();
  const styles = useStyles();

  const five = useMemo(() => Array.from('x'.repeat(5)), []);

  const Icon = props.provider === 'apple' ? AppleIcon : AndroidIcon;

  const count = props.count
    ? props.count.toLocaleString(params.lang, {
        notation: 'compact'
      })
    : '...';

  return (
    <div className={styles.outerWrapper}>
      <PanelItem spacing={false} paddingY={false}>
        <Icon className={styles.platformIcon} />
      </PanelItem>
      <div className={styles.flexWrapper}>
        <div className={styles.textWrapper}>
          <span className={styles.screenReaderOnly}>{title}</span>
          <p className={styles.platformTitle}>{title}</p>
          {props.count && (
            <p className={styles.reviewsCount}>
              {count} {t('REVIEWS_LOWERCASE')}
            </p>
          )}
        </div>
        <div className={styles.starsOuterWrapper}>
          <div className={styles.starsInnerWrapper}>
            {five.map((f, i) => (
              <StarIcon className={styles.starIcon} key={`${title}_review_start_${i}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
