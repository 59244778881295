import { HubSpotDatePickerField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotDatePickerField';
import { HubSpotDropdownField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotDropdownField';
import { HubSpotFormFieldLabel } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFieldLabel';
import { HubSpotMultipleCheckboxField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotMultipleCheckboxField';
import { HubSpotRadioField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotRadioField';
import { HubSpotSingleCheckboxField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotSingleCheckboxField';
import { HubSpotTextField } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotTextField';
import React from 'react';

export type HubSpotFormOption = {
  label: string;
  value: string;
  displayOrder?: number;
  description?: string;
};

export type HubSpotFormFieldType = {
  name: string;
  label: string;
  fieldType: string;
  required: boolean;
  hidden: boolean;
  options?: HubSpotFormOption[];
  placeholder?: string;
};

export type HubSpotFormFieldsType = {
  formFields: HubSpotFormFieldType[];
};

const hubspotFieldComponentMap = new Map([
  ['single_line_text', HubSpotTextField],
  ['multi_line_text', HubSpotTextField],
  ['email', HubSpotTextField],
  ['phone', HubSpotTextField],
  ['dropdown', HubSpotDropdownField],
  ['multiple_checkboxes', HubSpotMultipleCheckboxField],
  ['single_checkbox', HubSpotSingleCheckboxField],
  ['radio', HubSpotRadioField],
  ['datepicker', HubSpotDatePickerField]
]);

export const HubSpotFormFields = React.memo(({ formFields }: HubSpotFormFieldsType) => {
  return (
    <>
      {formFields?.map((field) => {
        if (field?.hidden || !field?.fieldType) return null;

        const HubSpotFieldComponent = hubspotFieldComponentMap.get(field?.fieldType);

        return HubSpotFieldComponent ? (
          <HubSpotFormFieldLabel key={field.name} field={field}>
            <HubSpotFieldComponent {...field} />
          </HubSpotFormFieldLabel>
        ) : (
          <></>
        );
      })}
    </>
  );
});

HubSpotFormFields.displayName = 'HubSpotFormFields';
