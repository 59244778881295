import { CarouselProps } from 'components/common/Carousel/Carousel';
import { useCarouselContext } from 'components/common/Carousel/CarouselProvider';
import { Asset } from 'components/contentful/Asset/Asset';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';

export const CarouselCurrentLogo = memo(({ items, className }: Omit<CarouselProps, 'children'>) => {
  const { currentIndex } = useCarouselContext();
  return (
    <PanelFlex
      justify="center"
      align="center"
      paddingX="MD"
      paddingY="MD"
      className={className ?? 'h-[100px]'}>
      <Asset entry={items[currentIndex]} />
    </PanelFlex>
  );
});

CarouselCurrentLogo.displayName = 'CarouselCurrentLogo';
