import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button';
import { Divider } from 'components/common/Divider';
import { FormSubmitButton } from 'components/common/Form';
import { Translation } from 'components/common/Translation';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';
import { useFormState } from 'react-hook-form';
import { useHubSpotFormAtom } from 'recoil/hubspotFormAtom/hubspotFormAtomHooks';

export const HubSpotFormButtons = memo(({ onCancel }: { onCancel: GenericHandler }) => {
  const { isSubmitSuccessful } = useFormState();
  const { hasError, errorMessage } = useHubSpotFormAtom();

  return (
    <PanelFlex column fullWidth paddingX="XS" paddingY={false}>
      {hasError && (
        <Alert intent="danger" message={errorMessage} hasCloseButton onClose={onCancel} fullWidth />
      )}
      <Divider spacing={false} />
      <PanelFlex fullWidth paddingX="XS" paddingY={false} className="pt-2">
        {!isSubmitSuccessful && <FormSubmitButton fullWidth />}
        <Button fullWidth type="button" onClick={onCancel}>
          <Translation textKey={isSubmitSuccessful ? 'CLOSE' : 'BUTTON_CANCEL'} />
        </Button>
      </PanelFlex>
    </PanelFlex>
  );
});

HubSpotFormButtons.displayName = 'HubSpotFormButtons';
