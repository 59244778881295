import { Translation } from 'components/common/Translation';
import { memo } from 'react';

export const NavbarSRTitle = memo(() => {
  return (
    <h2 className="sr-only">
      <Translation textKey="NAVIGATION" />
    </h2>
  );
});

NavbarSRTitle.displayName = 'NavbarSRTitle';
