import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const LoadingSpinnerIcon = React.memo((props: GlyphProps) => (
  <Glyph width={44} height={44} {...props}>
    <circle cx="22" cy="22" r="21" stroke="#266EBC" strokeOpacity="0.1" strokeWidth="2" />
    <path
      d="M43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="origin-center animate-spin"
    />
    <g filter="url(#filter0_dd_2)">
      <circle cx="22" cy="22" r="16" fill="white" />
    </g>
    <path
      d="M20.2111 15.5777L15.2777 25.4446C14.4648 27.0704 16.0668 28.85 17.7688 28.2117L21.2978 26.8883C21.7505 26.7186 22.2495 26.7186 22.7022 26.8883L26.2312 28.2117C27.9332 28.85 29.5352 27.0704 28.7223 25.4446L23.7889 15.5777C23.0518 14.1036 20.9482 14.1036 20.2111 15.5777Z"
      fill="#266EBC"
    />
    <defs>
      <filter
        id="filter0_dd_2"
        x="2"
        y="4"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow" />
        <feOffset />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="multiply" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="41.95"
        y1="23.05"
        x2="12.55"
        y2="32.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#266EBC" stopOpacity="0" />
        <stop offset="1" stopColor="#266EBC" stopOpacity="1" />
      </linearGradient>
    </defs>
  </Glyph>
));

LoadingSpinnerIcon.displayName = 'LoadingSpinnerIcon';

export { LoadingSpinnerIcon };
