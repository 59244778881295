import { FooterContainer } from 'components/PublicFooter/FooterContainer';
import { FooterSRTitle } from 'components/PublicFooter/FooterSRTitle';
import { memo } from 'react';
import { FooterBottomRow } from './FooterBottomRow';
import { FooterLinks } from './FooterLinks';

export const PublicFooter = memo(() => {
  return (
    <FooterContainer>
      <FooterSRTitle />
      <FooterLinks />
      <FooterBottomRow />
    </FooterContainer>
  );
});

PublicFooter.displayName = 'PublicFooter';
