'use client';
import { useHubSpotFormDefinition } from 'hooks/useHubSpotForm/useHubSpotFormDefinition';
import { useHubspotUTMFields } from 'hooks/useHubSpotForm/useHubSpotUTMFields';
import { pingEvent } from 'lib/analytics/event';
import { formatHubSpotFormFields } from 'lib/hubspot';
import { submitHubSpotFormData } from 'lib/hubspot/submitHubSpotFormData';
import { useCallback } from 'react';
import {
  useDisableHubSpotFormModal,
  useEnableHubSpotFormError,
  useEnableHubSpotFormModal,
  useHubSpotFormAtom,
  useToggleHubSpotFormModal
} from 'recoil/hubspotFormAtom/hubspotFormAtomHooks';

export const useHubSpotForm = () => {
  const { show, hasError, portalId, formId } = useHubSpotFormAtom();
  const hubspotUTMFields = useHubspotUTMFields();

  const disableTenstreetDemoModal = useDisableHubSpotFormModal();
  const enableHubSpotModal = useEnableHubSpotFormModal({ portalId, formId });
  const toggleHubSpotModal = useToggleHubSpotFormModal({ portalId, formId });
  const enableHubSpotformError = useEnableHubSpotFormError();

  const { formFields, loading, loadingError } = useHubSpotFormDefinition(formId);

  const handleSubmitFormData = useCallback(
    async (data) => {
      try {
        const fields = formatHubSpotFormFields({ ...data, ...hubspotUTMFields });

        const response = await submitHubSpotFormData({
          portalId,
          formId,
          fields
        });

        pingEvent('HUBSPOT_FORM_SUBMIT_SUCCESS');
        console.log('Form submitted successfully:', response);
      } catch (error) {
        pingEvent('HUBSPOT_FORM_SUBMIT_ERROR');
        enableHubSpotformError(error.message);

        console.error('Error submitting form:', error);
      }
    },
    [portalId, formId]
  );

  const handleCloseHubSpotForm = useCallback(
    ({ enablePingEvent = true }) => {
      enablePingEvent && pingEvent('HUBSPOT_FORM_CLOSE');
      disableTenstreetDemoModal();
    },
    [disableTenstreetDemoModal]
  );

  return {
    handleSubmitFormData,
    formFields,
    loading,
    loadingError,
    handleCloseHubSpotForm,
    show,
    enableHubSpotModal,
    toggleHubSpotModal,
    hasError
  };
};
