import { RefObject } from 'react';
import { useClickAway } from 'react-use';
import { useSetAutoCompleteOpen, useSetAutoCompleteVisible } from 'recoil/autoComplete';

import { useMobilePublicSearchBreakpoint } from './useMobilePublicSearchBreakpoint';

/**
 * Hook for adding useClickAway to PublicSearchInput
 * @returns useClickAway hook
 */
export const usePublicSearchInputClickAway = (searchWrapperRef: RefObject<HTMLDivElement>) => {
  const { isMobileSearchBreakpoint } = useMobilePublicSearchBreakpoint();
  const setSearchOpen = useSetAutoCompleteOpen('publicSearch');
  const setSearchVisible = useSetAutoCompleteVisible('publicSearch');

  return useClickAway(searchWrapperRef, () => {
    setSearchOpen(false);
    !isMobileSearchBreakpoint && setSearchVisible(false);
  });
};
