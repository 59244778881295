'use client';
import { Carousel } from 'components/common/Carousel/Carousel';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { TenstreetCarouselItemContent } from 'components/tenstreet/TenstreetPartnerLogosCarousel/TenstreetCarouselItemContent';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';
import { UIComponentProps } from 'types/contentful';

const useStyles = makeStyles()({
  root: ['relative overflow-hidden', 'bg-center bg-no-repeat bg-image-recruiting-road-lines'],
  decorator: ['text-2xl font-semibold text-brightBlue-600'],
  bodyText: ['max-w-2xl', 'mb-4', 'h-[120px] md:h-[100px]']
});

export const TenstreetPartnerLogosCarousel = memo(({ items }: UIComponentProps) => {
  const imageItems = items.get('Image');
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <PanelFlex row paddingX="LG" paddingY="MD" fullWidth>
        <Carousel items={imageItems}>
          {(item) => <TenstreetCarouselItemContent item={item} />}
        </Carousel>
      </PanelFlex>
    </div>
  );
});

TenstreetPartnerLogosCarousel.displayName = 'TenstreetPartnerLogosCarousel';
