export const fetchHubSpotFormDefinition = async (formId: string) => {
  try {
    const response = await fetch(`/api/hubspotForms/${formId}`);
    if (!response.ok) {
      throw new Error(`Error fetching form data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
