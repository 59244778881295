import { HubSpotFormProps } from 'components/tenstreet/HubSpotFormModal/HubSpotFormModal';
import { produce } from 'immer';
import { pingEvent } from 'lib/analytics/event';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { hubspotFormAtom } from 'recoil/hubspotFormAtom/hubspotFormAtom';

export const useHubSpotFormAtom = () => useRecoilValue(hubspotFormAtom);

export const useEnableHubSpotFormModal = ({ portalId, formId }: HubSpotFormProps, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        pingEvent('HUBSPOT_FORM_OPEN');
        set(
          hubspotFormAtom,
          produce((draft) => {
            draft.show = true;

            if (draft.portalId !== portalId || draft.formId !== formId) {
              draft.portalId = portalId;
              draft.formId = formId;
            }
          })
        );
      },
    [...deps, portalId, formId]
  );

export const useDisableHubSpotFormModal = (deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          hubspotFormAtom,
          produce((draft) => {
            draft.show = false;
            draft.hasError = false;
            draft.errorMessage = null;
            draft.portalId = null;
            draft.formId = null;
          })
        );
      },
    deps
  );

export const useToggleHubSpotFormModal = ({ portalId, formId }: HubSpotFormProps, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          hubspotFormAtom,
          produce((draft) => {
            draft.hasError = false;
            draft.errorMessage = null;

            if (draft.show) {
              draft.show = false;
              draft.portalId = null;
              draft.formId = null;
              return;
            }

            if (draft.portalId !== portalId || draft.formId !== formId) {
              draft.portalId = portalId;
              draft.formId = formId;
            }

            draft.show = true;
          })
        );
      },
    [...deps, portalId, formId]
  );

export const useEnableHubSpotFormError = (deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      (error?: string) => {
        set(
          hubspotFormAtom,
          produce((draft) => {
            draft.hasError = true;
            draft.errorMessage = error;
          })
        );
      },
    deps
  );

export const useDisableHubSpotFormError = (deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          hubspotFormAtom,
          produce((draft) => {
            draft.hasError = false;
            draft.errorMessage = null;
          })
        );
      },
    deps
  );
