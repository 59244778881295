'use client';
import { SliderPaginationRoot } from 'hooks/useSliderPagination';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

import { SliderFeatures } from './SliderFeatures';
import { SliderImage } from './SliderImage';
import { SliderNav } from './SliderNav';

const useStyles = makeStyles<ParentClassNameProp>()({
  wrapper: (props) => ['mt-10 lg:mt-0 relative w-full', props.className],
  hiddenLg: 'lg:hidden'
});

type SolutionsWarehouseSliderProps = { items: SerializedComponentItem[] } & ParentClassNameProp;

const SolutionsTruckingSlider = React.memo(
  ({ items, className }: SolutionsWarehouseSliderProps) => {
    const styles = useStyles({ className });
    return (
      <SliderPaginationRoot items={items} autoSlide={5000}>
        <div className={styles.wrapper}>
          <SliderImage />
          <div>
            <SliderFeatures />
            <SliderNav className={styles.hiddenLg} />
          </div>
        </div>
      </SliderPaginationRoot>
    );
  }
);

SolutionsTruckingSlider.displayName = 'SolutionsWarehouseSlider';

export { SolutionsTruckingSlider };
