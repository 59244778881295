import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const RoadWorkIcon = React.memo((props: GlyphProps) => (
  <Glyph width={46} height={41} {...props}>
    <path
      d="M8.61597 19.5081L22.5081 5.61597C22.9045 5.22147 23.441 5 24.0003 5C24.5596 5 25.0961 5.22147 25.4926 5.61597L39.381 19.5081C39.5772 19.7039 39.7329 19.9365 39.8391 20.1926C39.9453 20.4486 40 20.7231 40 21.0003C40 21.2775 39.9453 21.552 39.8391 21.8081C39.7329 22.0641 39.5772 22.2967 39.381 22.4926L25.4926 36.381C25.2967 36.5772 25.0641 36.7329 24.8081 36.8391C24.552 36.9453 24.2775 37 24.0003 37C23.7231 37 23.4486 36.9453 23.1926 36.8391C22.9365 36.7329 22.7039 36.5772 22.5081 36.381L8.61597 22.4926C8.22147 22.0961 8 21.5596 8 21.0003C8 20.441 8.22147 19.9045 8.61597 19.5081Z"
      fill="#F66102"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M8.61597 19.5081L22.5081 5.61597C22.9045 5.22147 23.441 5 24.0003 5C24.5596 5 25.0961 5.22147 25.4926 5.61597L39.381 19.5081C39.5772 19.7039 39.7329 19.9365 39.8391 20.1926C39.9453 20.4486 40 20.7231 40 21.0003C40 21.2775 39.9453 21.552 39.8391 21.8081C39.7329 22.0641 39.5772 22.2967 39.381 22.4926L25.4926 36.381C25.2967 36.5772 25.0641 36.7329 24.8081 36.8391C24.552 36.9453 24.2775 37 24.0003 37C23.7231 37 23.4486 36.9453 23.1926 36.8391C22.9365 36.7329 22.7039 36.5772 22.5081 36.381L8.61597 22.4926C8.22147 22.0961 8 21.5596 8 21.0003C8 20.441 8.22147 19.9045 8.61597 19.5081Z"
      fill="#F66102"
      stroke="black"
      strokeWidth="1.9"
      strokeMiterlimit="10"
    />
    <path
      d="M20.2385 14.9507C20.4255 14.749 20.5355 14.5841 20.6748 14.5327C20.7342 14.5217 20.7951 14.5217 20.8545 14.5327C21.2451 14.6741 21.6459 14.7857 22.0534 14.8664C22.3245 14.8813 22.5821 14.9894 22.7826 15.1725C22.983 15.3556 23.114 15.6024 23.1534 15.871C23.4467 18.1992 23.7657 20.5017 24.1323 22.8079C24.2167 23.223 24.4191 23.6049 24.7153 23.9078C25.5402 24.6631 26.4275 25.3487 27.2818 26.0563C27.4358 26.181 27.6081 26.2873 27.9637 26.5403C27.8647 26.0893 27.8171 25.84 27.7547 25.5944C27.4174 24.3478 28.1654 23.4238 29.4156 23.6951C30.8015 23.9921 32.0445 24.3954 33.4084 24.7951C33.6009 24.8953 33.7807 25.0184 33.9437 25.1617L29.1773 29.5614C29.1773 29.5614 25.7345 30.2947 24.9719 29.5614C24.7739 29.3708 24.8619 28.9821 24.9499 28.7475C25.2249 28.0142 25.5952 27.3066 25.8775 26.5476C25.9618 26.3276 25.9692 25.9207 25.8335 25.8143C24.6713 24.8244 23.4723 23.8858 22.1928 22.8555C22.364 23.5042 22.4866 24.1648 22.5594 24.8317C22.6144 26.1993 22.5924 27.5742 22.5594 28.9455C22.5629 28.9882 22.5629 29.0311 22.5594 29.0738C22.5444 29.1654 22.5106 29.2529 22.4601 29.3307C22.4096 29.4086 22.3435 29.4751 22.266 29.5261C22.1884 29.5771 22.1012 29.6114 22.0097 29.627C21.9182 29.6425 21.8245 29.6389 21.7344 29.6164C21.3678 29.5101 21.3458 29.0591 21.3311 28.7438C21.2908 27.8345 21.3311 26.9106 21.2945 26.0123C21.2578 25.1141 20.8032 24.3294 20.0992 23.5998C19.9416 25.334 19.8096 26.9583 19.6299 28.5788C19.5896 28.9198 19.5346 29.3671 19.2413 29.5651C19.1323 29.6185 19.0125 29.6463 18.8911 29.6463C18.7698 29.6463 18.65 29.6185 18.541 29.5651C18.4699 29.4734 18.4177 29.3685 18.3875 29.2564C18.3573 29.1444 18.3497 29.0275 18.365 28.9125C18.365 26.346 18.3357 23.7391 18.3943 21.1543C18.42 19.9664 18.09 19.7611 18.0277 18.5658C17.8994 17.0259 19.1863 16.08 20.2385 14.9507ZM22.926 22.2542L22.4127 19.5301C21.5658 21.048 21.6941 21.7299 22.926 22.2542Z"
      fill="black"
    />
    <path
      d="M22.5448 10.8663C22.7845 10.8632 23.0224 10.9088 23.244 11.0003C23.4656 11.0918 23.6663 11.2274 23.8339 11.3988C24.0016 11.5702 24.1327 11.7739 24.2193 11.9975C24.3059 12.2211 24.3461 12.4599 24.3377 12.6995C24.3412 13.0101 24.2657 13.3165 24.1184 13.5899C23.9711 13.8633 23.7567 14.0948 23.4954 14.2627C23.234 14.4305 22.9344 14.5292 22.6244 14.5495C22.3145 14.5698 22.0045 14.5111 21.7235 14.3787C21.3321 14.1871 21.0178 13.8676 20.8326 13.4731C20.7006 13.1899 20.6432 12.8776 20.6658 12.5659C20.6885 12.2543 20.7904 11.9536 20.962 11.6924C21.1335 11.4312 21.369 11.2182 21.646 11.0736C21.923 10.9291 22.2324 10.8577 22.5448 10.8663Z"
      fill="black"
    />
  </Glyph>
));

RoadWorkIcon.displayName = 'RoadWorkIcon';

export { RoadWorkIcon };
