'use client';
import { ControlInput } from 'components/common/Input/ControlInput';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Translation } from '../../Translation';

export type YesNoGroupProps = {
  name?: string;
  yesLabel?: string;
  noLabel?: string;
  hookFormField?: string;
  onChange?: (value: YesNoValues) => void;
};

export enum YesNoValues {
  YES = 'true',
  NO = 'false'
}

export const YesNoGroup = ({
  hookFormField,
  name = hookFormField,
  yesLabel,
  noLabel,
  onChange
}: YesNoGroupProps) => {
  if (!name) {
    throw new Error('YesNoGroup: a prop "name" or "hookFormField" is required');
  }
  if (hookFormField && !useFormContext()) {
    throw new Error(
      'YesNoGroup: React hook form <FormContext> must be wrapping field to use hookFormField prop'
    );
  }

  const watchValue = hookFormField && useFormContext().watch(hookFormField);
  const setHookFormValue = hookFormField && useFormContext().setValue.bind(null, name);
  const [stateValue, setStateValue] = useState<YesNoValues>();

  // input radio onChange on wrapper was causing issues, we ended up controlling state
  const onClickControlled = useCallback(
    (value: YesNoValues) => {
      if (hookFormField) {
        if (value !== watchValue) {
          setHookFormValue(value);
          onChange && onChange(value);
        }

        return;
      }

      if (value !== stateValue) {
        setStateValue(value);
        onChange && onChange(value);
      }
    },
    [hookFormField, setHookFormValue, setStateValue, onChange]
  );

  const options = [
    {
      node: (
        <span className={'px-1.5'}>
          {yesLabel?.trim()?.length ? yesLabel : <Translation textKey="RADIO_YES" />}
        </span>
      ),
      checked: (hookFormField && watchValue === YesNoValues.YES) || stateValue === YesNoValues.YES,
      value: YesNoValues.YES
    },
    {
      node: (
        <span className={'px-1.5'}>
          {noLabel?.length ? noLabel : <Translation textKey="RADIO_NO" />}
        </span>
      ),
      checked: (hookFormField && watchValue === YesNoValues.NO) || stateValue === YesNoValues.NO,
      value: YesNoValues.NO
    }
  ];

  return <ControlInput name={name} options={options} onClickControlled={onClickControlled} />;
};

YesNoGroup.displayName = 'YesNoGroup';
