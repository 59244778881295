'use client';
import { useCallback, useState } from 'react';

const useDynamicOffset = () => {
  const [offset, setOffset] = useState(0);

  const ref = useCallback((node) => {
    if (node !== null) {
      setOffset(node.getBoundingClientRect().width / 2);
    }
  }, []);

  return { ref, offset };
};

export { useDynamicOffset };
