import { iconComponentResolver } from 'lib/contentful/componentResolver';
import { makeStyles } from 'lib/makeStyles';
import { navigationIconMap } from 'models/NavigationModel';
import React from 'react';

import { NavLinkInnerProps } from './types';

export const useStyles = makeStyles({
  icon: 'shrink-0 mr-3 md:-ml-2 text-3xl',
  primaryText: 'text-sm font-medium text-secondaryText',
  secondaryText: 'mt-1 text-xs text-neutralTextLoud'
});

export const NavLinkContent: React.ComponentType<NavLinkInnerProps> = React.memo(
  ({ icon, primaryText, secondaryText }) => {
    const styles = useStyles();
    const Icon = icon ? iconComponentResolver(navigationIconMap.get(icon)) : null;

    return (
      <>
        {Icon && <Icon size="md" className={styles.icon} />}
        <div>
          <p className={styles.primaryText}>{primaryText}</p>
          {secondaryText && <p className={styles.secondaryText}>{secondaryText}</p>}
        </div>
      </>
    );
  }
);

NavLinkContent.displayName = 'NavLinkContent';
