import { SelectItem } from 'components/common/SelectItem';
import React, { useCallback } from 'react';
import { useGridOverlapStyles } from 'styles';

import { Checkbox } from './Checkbox';
import { SelectInputProps, useStyles as useRadioStyles } from './RadioInput';

export type CheckboxInputProps = SelectInputProps &
  React.ComponentPropsWithoutRef<'input'> &
  Required<Pick<JSX.IntrinsicElements['input'], 'name'>>;

export const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      children,
      id,
      name,
      checked,
      disabled,
      indeterminate,
      icon,
      hideInput,
      paddingY,
      intent,
      customTextColor,
      ...inputProps
    }: CheckboxInputProps,
    ref
  ) => {
    const styles = useRadioStyles({
      disabled,
      hideInput,
      paddingY
    });

    const gridStyles = useGridOverlapStyles({ className: styles.gridMargin });
    const checkboxInputClassName = `${gridStyles.gridItem} pl-1`;
    const fieldId = `checkbox_${id ?? name}`;

    const renderContent = useCallback(
      () => (
        <SelectItem
          paddingY={paddingY}
          className={hideInput ? gridStyles.gridItem : undefined}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          customTextColor={customTextColor}
          intent={intent}
          icon={icon}>
          {children}
        </SelectItem>
      ),
      [icon, children, paddingY, checked, disabled, indeterminate, customTextColor, intent]
    );

    return (
      <label htmlFor={fieldId} className={styles.root}>
        <div className={gridStyles.grid}>
          <div className={checkboxInputClassName}>
            <input
              type="checkbox"
              key={`checkbox_${fieldId}`}
              id={fieldId}
              tabIndex={0}
              disabled={disabled}
              className={styles.ghostInput}
              ref={ref}
              {...inputProps}
            />
          </div>
          {!hideInput ? (
            <div aria-hidden={true} className={gridStyles.gridItem}>
              <Checkbox
                type={'checkbox'}
                checked={checked}
                disabled={disabled}
                indeterminate={indeterminate}
              />
            </div>
          ) : (
            renderContent()
          )}
        </div>
        {!hideInput && renderContent()}
      </label>
    );
  }
);

CheckboxInput.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
  paddingY: 'MD',
  hideInput: false
};

CheckboxInput.displayName = 'CheckboxInput';
