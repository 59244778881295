'use client';
import { Container } from 'components/layout/Container';
import { usePageContext } from 'components/Page/PageContext';
import { makeStyles } from 'lib/makeStyles';
import { ErrorCodes } from 'types/common';

type ErrorPageCustomStyleProps = {
  wrapperClassName?: string;
  containerClassName?: string;
};

export const useStyles = makeStyles<ErrorPageCustomStyleProps>()({
  wrapper: (props) => ['w-full min-h-[48vh] overflow-hidden', props.wrapperClassName],
  // using gray palette classes here because this is a custom gradient
  gradient: [
    'h-full w-full',
    'bg-gradient-to-t from-neutralBackgroundLoud/75 lg:from-neutralBackgroundLoud/90'
  ],
  errorCode: ['m-auto', 'text-[30vw] xl:text-[400px] font-extrabold text-neutralBackgroundLoud'],
  container: (props) => [
    'h-full',
    'flex justify-center items-center',
    'pt-16 md:pt-0',
    props.containerClassName
  ]
});

const ErrorPageHeroBackground = () => {
  const { statusCode = ErrorCodes.ERROR } = usePageContext();
  const styles = useStyles({ wrapperClassName: 'z-1' });

  return (
    <div className={styles.wrapper}>
      <div className={styles.gradient}>
        <Container className={styles.container}>
          <div className={styles.errorCode}>{statusCode}</div>
        </Container>
      </div>
    </div>
  );
};

ErrorPageHeroBackground.displayName = 'ErrorPageHeroBackground';

export { ErrorPageHeroBackground };
