import { useMemo } from 'react';

import { useBreakpoints } from '../useUI/useBreakpoints';

/**
 * Checks mobile search breakpoint
 * @returns boolean if current breakpoint matches mobile search breakpoint
 */

export const useMobilePublicSearchBreakpoint = () => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const mobileSearchBreakpointsRegex = `${breakpoints.SM}|${breakpoints.XS}|${breakpoints.MD}`;
  return useMemo(() => {
    return {
      isMobileSearchBreakpoint: currentBreakpoint.match(mobileSearchBreakpointsRegex)
    };
  }, [currentBreakpoint]);
};
