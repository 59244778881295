'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

type ActionCallback = () => void;

const useOnRouteChange = (action: ActionCallback): void => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    // Call the action callback when pathname or searchParams change
    action();
  }, [pathname, searchParams, action]);
};

export { useOnRouteChange };
