import { CarouselProps } from 'components/common/Carousel/Carousel';
import { useCarouselContext } from 'components/common/Carousel/CarouselProvider';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { useTranslation } from 'hooks/useContentful';
import { ChevronIcon } from 'icons/monochrome/ChevronIcon';
import { memo } from 'react';

const getPlaceholderClassName = (active = false) =>
  `w-2 h-2 mx-auto ${active ? 'bg-brightBlue-600' : 'bg-secondaryIcon'} rounded-full`;

const getIconButtonClassName = (rotate = false) => `w-5 h-5 ${rotate ? 'rotate-180' : ''}`;

export const CarouselButtons = memo(({ items }: Pick<CarouselProps, 'items'>) => {
  const { currentIndex, goToNext, goToPrevious, goToIndex } = useCarouselContext();
  const { t } = useTranslation();

  return (
    <PanelFlex row justify="center" align="center" paddingY="2XL" spacing="LG">
      <button onClick={goToPrevious} title={t('GO_TO_PREVIOUS')}>
        <ChevronIcon className={getIconButtonClassName(true)} />
      </button>
      <PanelFlex row justify="center" align="center" paddingY="SM" spacing={false}>
        {items.map((_, index) => {
          const active = index === currentIndex;
          return (
            <button
              key={index}
              className={active ? 'active' : ''}
              onClick={() => goToIndex(index)}
              title={`${t('CAROUSEL_ITEM')} ${index + 1}`}
              // Minimum size requirements for WCAG 2.2
              style={{ height: 24, width: 24 }}>
              <div className={getPlaceholderClassName(active)} />
            </button>
          );
        })}
      </PanelFlex>
      <button onClick={goToNext} title={t('GO_TO_NEXT')}>
        <ChevronIcon className={getIconButtonClassName()} />
      </button>
    </PanelFlex>
  );
});

CarouselButtons.displayName = 'CarouselButtons';
