import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { ContentfulQueryProps } from 'types/common';

import { ColorVariantWrapper } from 'components/common/ColorVariantWrapper';
import { PageUIComponents } from 'components/contentful/PageUIComponents';
import { ClientSideDeviceSecret } from 'components/DeviceSecret/ClientSideDeviceSecret';
import { ClientPageProvider } from 'components/Page/ClientPageProvider';
import { PublicFooter } from 'components/PublicFooter';
import { PublicNavigation } from 'components/PublicNavigation';
import { PageContextTypes } from './PageContext';

export const usePageStyles = makeStyles<Pick<PageProps, 'disableScroll'>>()({
  root: (props) => [
    'flex flex-col',
    'min-h-screen',
    'bg-neutralBackgroundSoft dark:bg-primaryBackgroundDark',
    {
      'overflow-hidden': props?.disableScroll
    }
  ]
});

export type PageProps = PageContextTypes &
  ContentfulQueryProps & {
    disableScroll?: boolean;
  } & ChildrenProp;

export const Page: React.ComponentType<PageProps> = ({
  contentfulQuery,
  children,
  disableScroll = false,
  ...pageContextValues
}) => {
  const styles = usePageStyles({ disableScroll });
  const { variant } = pageContextValues?.page?.fields || {
    uiComponents: [],
    variant: 'DEFAULT'
  };

  return (
    <ColorVariantWrapper variant={variant}>
      <div className={styles.root}>
        <ClientPageProvider contentfulQuery={contentfulQuery} {...pageContextValues}>
          <PublicNavigation />
          {children ?? (
            <PageUIComponents uiComponents={pageContextValues.page?.fields?.uiComponents} />
          )}
          <PublicFooter />
        </ClientPageProvider>
        <ClientSideDeviceSecret />
      </div>
    </ColorVariantWrapper>
  );
};
