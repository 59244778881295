import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const GuardShackIcon = React.memo((props: GlyphProps) => (
  <Glyph width={40} height={40} {...props}>
    <g filter="url(#filter0_d_guard_shack)">
      <circle cx="20" cy="19" r="16" fill="#00BAA4" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9866 15.1318C27.6777 17.4985 26.4101 22.9177 21.2088 27.543C20.5265 28.1498 19.5018 28.1513 18.8184 27.5457C14.0281 23.3009 12.3512 18.2833 12.0097 15.1363C11.9291 14.393 12.3571 13.7077 13.0212 13.3642L19.0943 10.2235C19.6706 9.9255 20.3556 9.9255 20.9318 10.2235L27.0049 13.3642C27.6691 13.7077 28.0834 14.3903 27.9866 15.1318ZM20.4907 14.3979C20.3441 13.931 19.6833 13.931 19.5367 14.3979L18.9909 16.1353C18.9254 16.3437 18.7323 16.4855 18.5139 16.4855H16.7126C16.2324 16.4855 16.0283 17.0967 16.4122 17.3852L17.8982 18.5018C18.0675 18.6291 18.1383 18.8493 18.0748 19.0514L17.5141 20.8361C17.3685 21.2998 17.903 21.6777 18.2915 21.3857L19.7133 20.3173C19.8913 20.1836 20.1361 20.1836 20.3141 20.3173L21.7359 21.3857C22.1244 21.6777 22.6589 21.2998 22.5133 20.8361L21.9526 19.0514C21.8891 18.8493 21.9599 18.6291 22.1292 18.5018L23.6152 17.3852C23.9991 17.0967 23.795 16.4855 23.3148 16.4855H21.5135C21.2951 16.4855 21.102 16.3437 21.0365 16.1353L20.4907 14.3979Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_guard_shack"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

GuardShackIcon.displayName = 'GuardShackIcon';

export { GuardShackIcon };
