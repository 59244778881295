import { DateTime, DateTimePropsType } from '@truckmap/web-datepicker';
import { Dayjs } from 'dayjs';
import { makeStyles } from 'lib/makeStyles';
import React, { memo, ReactNode, useRef } from 'react';

import { CalendarDate } from './CalendarDate';
import { CalendarView } from './CalendarView';

export type CalendarProps = {
  id?: string;
  value: Dayjs;
  changeHandler: GenericHandler;
  input: ReactNode;
  show?: boolean;
  toggleOnSelect?: boolean;
  append?: ReactNode;
  prepend?: ReactNode;
  handleValidDate?: DateTimePropsType['isValidDate'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDateWithinRange?: (currentDate: any) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDateRangeBound?: (currentDate: any) => boolean;
};

const useStyles = makeStyles({
  calendarHeader: 'text-sm font-medium px-1/2',
  calendarArrows: 'text-lg cursor-pointer hover:text-tmBlue'
});

const Calendar = memo(
  ({
    id = 'calendar_wrapper',
    input,
    changeHandler,
    append,
    prepend,
    show,
    handleValidDate = () => true,
    handleDateWithinRange,
    handleDateRangeBound,
    value
  }: CalendarProps) => {
    const dateTimeRef = useRef(null);
    const renderDay = React.useCallback(
      (props, currentDate, selectedDate) => {
        return (
          <CalendarDate
            currentDate={currentDate}
            selectedDate={selectedDate}
            isDateRangeBound={handleDateRangeBound && handleDateRangeBound(currentDate)}
            isDateWithinRange={handleDateWithinRange && handleDateWithinRange(currentDate)}
            {...props}
          />
        );
      },
      [handleDateRangeBound, handleDateWithinRange]
    );

    // disables month and year views
    const onBeforeNavigate = React.useCallback((): string => 'days', []);

    const renderView = React.useCallback(
      (mode, renderDefault) => {
        return (
          <CalendarView show={show} append={append} prepend={prepend}>
            {renderDefault()}
          </CalendarView>
        );
      },
      [show, append, prepend]
    );

    const styles = useStyles();

    return (
      <>
        {input}
        <div id={id} ref={dateTimeRef} className="w-max">
          <DateTime
            renderView={renderView}
            renderDay={renderDay}
            value={value}
            onChange={changeHandler}
            isValidDate={handleValidDate}
            onBeforeNavigate={onBeforeNavigate}
            timeFormat={false}
            input={false}
            viewOptions={{
              days: {
                css: {
                  dowInner: styles.calendarHeader,
                  rdtSwitchInner: styles.calendarHeader,
                  rdtPrevInner: styles.calendarArrows,
                  rdtNextInner: styles.calendarArrows
                }
              }
            }}
          />
        </div>
      </>
    );
  }
);

Calendar.displayName = 'Calendar';

export { Calendar };
