import { emailValidationPattern, phoneValidationPattern } from 'components/common/Form';
import { TextInput } from 'components/common/Input';
import {
  phoneNumberMask,
  phonePlaceholder
} from 'components/Place/PlaceDetails/PlaceDetailsContent/Rows/Columns/Forms/PlaceDetailsStringForm';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

export const HubSpotTextField = memo((field: HubSpotFormFieldType) => {
  const { register } = useFormContext();

  const isTextArea = field.fieldType === 'multi_line_text';
  const isPhone = field.fieldType === 'phone' || field.name === 'phone';
  const isEmail = field.fieldType === 'email' || field.name === 'email';
  const placeholder = isPhone ? phonePlaceholder : field.placeholder;

  return (
    <TextInput
      name={field.name}
      placeholder={placeholder}
      textarea={isTextArea}
      mask={isPhone && phoneNumberMask}
      className={isTextArea ? 'min-h-[70px]' : undefined}
      {...register(field.name, {
        required: field.required,
        pattern: isPhone ? phoneValidationPattern : isEmail ? emailValidationPattern : undefined
      })}
    />
  );
});

HubSpotTextField.displayName = 'HubSpotTextField';
