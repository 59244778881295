'use client';
import { assetLoader } from 'components/contentful/Asset';
import { CSSProperties, memo } from 'react';
import { SerializedComponentItem } from 'types/contentful';

export const AssetBackground = memo(
  ({
    imageItem,
    children,
    className,
    style: styleProps
  }: { imageItem: SerializedComponentItem; style?: CSSProperties } & ChildrenProp &
    ParentClassNameProp) => {
    const imageUrl = imageItem?.assets[0]?.url;
    const assetUrl = assetLoader({ src: imageUrl });

    const defaultStyle: CSSProperties = {
      backgroundImage: `url(${assetUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    };

    const style: CSSProperties = { ...defaultStyle, ...styleProps };

    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }
);

AssetBackground.displayName = 'AssetBackground';
