import { useCallback } from 'react';

/**
 * Returns common calendar days from Dayjs
 * @returns
 */
export const useCalendar = () => {
  const isToday = useCallback((currentDate, selectedDate): boolean => {
    if (!selectedDate) return false;

    return (
      currentDate?.date() === selectedDate?.date() &&
      currentDate?.month() === selectedDate?.month() &&
      currentDate?.year() === selectedDate?.year()
    );
  }, []);

  const getCurrentMonth = useCallback((): string => {
    return 'August';
  }, []);

  const getCurrentYear = useCallback((): string => {
    return '2022';
  }, []);

  return {
    isToday,
    getCurrentMonth,
    getCurrentYear
  };
};
