import { fetchPlaceSearchRedirectUrl } from 'clients/places/fetchPlaceSearchRedirectUrl';
import { makeStyles } from 'lib/makeStyles';
import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';

type PlaceSearchNavItemProps = ParentClassNameProp &
  ChildrenProp & {
    href: string;
  };

const usePlaceSearchNavItemStyles = makeStyles<Pick<PlaceSearchNavItemProps, 'className'>>()({
  link: (props) => [props.className]
});

// this is not a ref to avoid extra request when component is remounted
let searchUrlCache = null;

export const PlaceSearchNavbarItem: React.FC<PlaceSearchNavItemProps> = React.memo(
  ({ href, children, className }) => {
    const styles = usePlaceSearchNavItemStyles({ className });
    const router = useRouter();

    // saves /places middleware full page load
    const onPlaceNavbarItemClick = useCallback(
      async (event: React.BaseSyntheticEvent) => {
        event.preventDefault();

        if (searchUrlCache) {
          router.push(searchUrlCache);

          return;
        }

        try {
          const searchUrl = await fetchPlaceSearchRedirectUrl();

          searchUrlCache = searchUrl;
          router.push(searchUrl);

          // if edge function fails, redirect to places middleware
        } catch (error) {
          console.error(error);

          router.push(href);
        }
      },
      [router]
    );

    return (
      <a className={styles.link} onClick={onPlaceNavbarItemClick} href={href}>
        {children}
      </a>
    );
  }
);

PlaceSearchNavbarItem.displayName = 'PlaceSearchNavbarItem';
