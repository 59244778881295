import { useOnRouteChange } from 'hooks/useOnRouteChange';
import { useRecoilValue } from 'recoil';
import {
  autoCompleteAtom,
  useClearAutoComplete,
  useHideAutoComplete,
  useResetAutoComplete,
  useSetAutoCompleteValue,
  useShowAutoComplete
} from 'recoil/autoComplete';

/**
 * Hook for controlling the SearchInput value
 * @returns Methods for controlling the search input and state values.
 */
export const usePublicSearchInput = () => {
  const { value } = useRecoilValue(autoCompleteAtom('publicSearch'));

  const handleResetValue = useClearAutoComplete('publicSearch');
  const handleOnRouteChange = useResetAutoComplete('publicSearch');
  const onFocus = useShowAutoComplete('publicSearch');
  const onBlur = useHideAutoComplete('publicSearch');
  const onChange = useSetAutoCompleteValue('publicSearch');

  useOnRouteChange(handleOnRouteChange);

  return { value, onChange, onBlur, onFocus, resetValue: handleResetValue };
};
