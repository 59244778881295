import { Button } from 'components/common/Button';
import { Translation } from 'components/common/Translation';
import { BodyText } from 'components/common/Typography';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

type TopSearchResultItemProps = {
  icon: JSX.Element;
  title: string;
  description: string;
  href: string;
};

export const useStyles = makeStyles()({
  containerWrapper: 'my-2 w-full md:w-[200px]',
  flexContainer: 'flex flex-col justify-start',
  iconWrapper: 'my-2',
  contentWrapper: 'flex flex-col space-y-1 text-left',
  buttonWrapper: 'my-4'
});

export const TopSearchResultsItem = React.memo(
  ({ icon, title, description, href }: TopSearchResultItemProps) => {
    const styles = useStyles();

    return (
      <div className={styles.containerWrapper}>
        <div className={styles.flexContainer}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div className={styles.contentWrapper}>
            <BodyText bold>{title}</BodyText>
            <BodyText>{description}</BodyText>
          </div>
          <div className={styles.buttonWrapper}>
            <NonPrefetchLink href={href}>
              <Button>
                <Translation textKey="VIEW_MORE" />
              </Button>
            </NonPrefetchLink>
          </div>
        </div>
      </div>
    );
  }
);

TopSearchResultsItem.displayName = 'TopSearchResultsItem';
