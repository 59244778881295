'use client';
import cx from 'clsx';
import { TextInput } from 'components/common/Input';
import { BodyText, HeadingText } from 'components/common/Typography';
import { useStyles as useHomepagePinpointStyles } from 'components/Homepage/HomepageSectionPinpoint/HomepageSectionPinpoint';
import { Container } from 'components/layout/Container';
import { SearchInputIcon } from 'components/PublicSearch/SearchInputIcon';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { usePublicSearchInputOpen } from 'hooks/usePublicSearchInput';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

export const useStyles = makeStyles()({
  containerWrapper: ['py-12', 'h-[500px] md:h-[350px]'],
  backgroundClassName:
    'relative h-full bg-image-homepage-section-pinpoint-lg bg-pos-pinpoint-center md:bg-top lg:bg-right-top',
  gradientBackground: [
    'absolute md:hidden top-[100px] z-0',
    'bg-gradient-to-b from-primaryBackgroundDark',
    'h-[300px] w-full'
  ],
  container: ['relative z-10', 'px-4'],
  contentWrapper: 'py-4 flex flex-col space-y-2',
  searchInputWrapper: 'my-4 relative max-w-lg',
  bottomContainer: 'md:hidden',
  textInput: 'w-full max-w-lg pl-8',
  icon: 'left-3 absolute'
});

type Props = UIComponentProps;

const ErrorPageSearchBanner = React.memo((props: Props) => {
  const styles = useStyles();
  const [heading, subheading] = props?.entry?.richTextSections || [];
  const { t } = useTranslation();
  const openSearchInput = usePublicSearchInputOpen();

  const homepagePinpointStyles = useHomepagePinpointStyles({
    backgroundClassName: styles.backgroundClassName
  });

  return (
    <div className={cx(homepagePinpointStyles.containerOuter, styles.containerWrapper)}>
      <Container className={styles.container}>
        <div className={styles.contentWrapper}>
          {heading && (
            <HeadingText dark size="MD" as="h2">
              {heading.primaryText}
            </HeadingText>
          )}
          {subheading && (
            <BodyText dark large>
              {subheading.primaryText}
            </BodyText>
          )}
        </div>
        <div className={styles.searchInputWrapper}>
          <TextInput
            onClick={() => openSearchInput()}
            placeholder={t('PLACEHOLDER_START_TYPING')}
            className={styles.textInput}
          />
          <SearchInputIcon iconClassName={styles.icon} />
        </div>
      </Container>
      <div className={styles.gradientBackground} />
    </div>
  );
});

ErrorPageSearchBanner.displayName = 'ErrorPageSearchBanner';

export { ErrorPageSearchBanner };
