'use client';
import clsx from 'clsx';
import { Container } from 'components/layout/Container';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { autoCompleteAtom } from 'recoil/autoComplete';

export const NavbarContainer = memo(({ children }: ChildrenProp) => {
  const { visible: searchVisible } = useRecoilValue(autoCompleteAtom('publicSearch'));

  return <Container className={clsx(searchVisible && 'px-3 md:px-6')}>{children}</Container>;
});

NavbarContainer.displayName = 'NavbarContainer';
