'use client';
import { useStickyVariant } from 'hooks/useUI/useStickyVariant';
import { memo } from 'react';

export const NavbarStickyVariant = memo(() => {
  const thresholdRef = useStickyVariant();

  return <div ref={thresholdRef} />;
});

NavbarStickyVariant.displayName = 'NavbarStickyVariant';
