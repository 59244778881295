'use client';
import {
  ApiPaginationResponse,
  buildLatLon,
  fetchPlace,
  fetchPlaceReviews,
  fetchPlaceReviewVoteSummary,
  IPlace,
  IPlaceDetail,
  IPlaceMetaType,
  IPlaceReview,
  parsePlaceMetaTypeSections,
  PlaceMetaTypeSectionList,
  PlaceMetaTypeSectionListItem,
  PlaceMetaTypeSlug
} from '@truckmap/common';
import { usePageContext } from 'components/Page/PageContext';
import { PlaceReviewVoteSummaryResponse } from 'components/Place/PlaceDetails';
import { getPlaceDetailKey, getPlaceReviewsKey, getPlaceReviewVoteSummaryKey } from 'lib/swrCache';
import uniqBy from 'lodash/uniqBy';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useSWR, { useSWRConfig } from 'swr';
import { truckMapConfig } from 'truckMapConfig';
import { PlaceRoutes } from 'types/common';

import { placeDetailsHiddenFormAtom } from '../../recoil/placeDetails';

export const getDetailText = (detail: IPlaceDetail) =>
  detail.placeMetaType?.nameDisplay ?? detail.placeMetaType?.name;

export const usePlaceContext = () => usePageContext()?.placeContext;
export const usePlace = () => usePlaceContext()?.place;
export const usePlaceDetails = () => usePlace()?.placeDetails;
export const usePlaceCreateModal = () => usePlaceContext()?.placeCreateModal;
export const usePlaceSectionsState = () => usePlaceContext()?.placeSections;
export const usePlaceSections = () => usePlaceSectionsState()?.data;
export const usePlaceNearbyPoints = () => usePlaceContext()?.placeNearbyPoints;
export const usePlaceDataType = () => usePlaceContext()?.placeDataType;
export const usePlaceSummary = () => usePlaceContext()?.placeSummary;
export const usePlaceVoteSummary = () => usePlaceContext()?.voteSummary;
export const usePlaceAnnotationsData = () => usePlaceContext()?.placeAnnotationsData;

export const usePlaceDetailsHiddenForm = (metaId: string) => {
  const activeFormMetaId = useRecoilValue(placeDetailsHiddenFormAtom);
  const setHiddenFormState = useSetRecoilState(placeDetailsHiddenFormAtom);

  return {
    formId: `#${metaId}`,
    formKey: `InlineForm-${metaId}`,
    showForm: metaId === activeFormMetaId,
    toggleActiveForm: () => setHiddenFormState(metaId === activeFormMetaId ? '' : metaId)
  };
};

export const usePlaceDetailsShowReportProblemModal = () => {
  const {
    placeType: { objectKey }
  } = usePlace();

  return objectKey !== 'place_type:city';
};

export const usePlaceMetatypeExtraData = (slug: PlaceMetaTypeSlug) =>
  usePlaceContext()?.extraPlaceDetailsData[slug];

export const usePlaceURLs = () => {
  return (useRouter().query.relativePath ?? []) as [
    string,
    PlaceRoutes.REVIEWS | PlaceRoutes.LOCAL_MAP | undefined
  ];
};
export const usePlaceStore = (place: IPlace) =>
  useSWR<IPlace>(getPlaceDetailKey(place.id), () => fetchPlace(place.id));

export const usePlaceReviewVoteSummaryStore = (place: IPlace) =>
  useSWR<PlaceReviewVoteSummaryResponse>(getPlaceReviewVoteSummaryKey(place.id), () =>
    fetchPlaceReviewVoteSummary(place.id)
  );

export const usePlaceReviewStore = (place: IPlace) => {
  const {
    query: {
      relativePath: [, , page]
    }
  } = useRouter();
  const pageNumber = page ? parseInt(page, 10) : 1;

  return useSWR<ApiPaginationResponse<IPlaceReview[]>>(getPlaceReviewsKey(place.id), () =>
    fetchPlaceReviews({
      placeId: place.id,
      limit: truckMapConfig.placeDetails.reviewsPerPage,
      page: pageNumber
    })
  );
};

export const usePlaceSectionsContextState = (metatypesWithDetails: PlaceMetaTypeSectionList) => {
  const [data, mutate] = useState(metatypesWithDetails);

  return {
    data,
    mutate
  };
};

export const usePlaceCoordinates = (currentPlace?: IPlace) => {
  const place = currentPlace ?? usePlace();

  return useMemo(() => buildLatLon(place), [place]);
};

export const useValidPlaceTypes = () => {
  const { allPlaceTypes = null } = usePlaceContext();

  return useMemo(
    () => allPlaceTypes.filter((placeType) => placeType.canBecomePlace),
    [allPlaceTypes]
  );
};

let placeRevalidationDebouncer;
export const usePlaceRevalidate = () => {
  const place = usePlace();
  const { mutate: revalidate } = useSWRConfig();
  const { mutate } = usePlaceSectionsState();
  const { placeTypeConfig, allPlaceMetaTypes } = usePlaceContext();

  return async () => {
    placeRevalidationDebouncer && clearTimeout(placeRevalidationDebouncer);
    placeRevalidationDebouncer = setTimeout(() => {
      revalidate(getPlaceDetailKey(place.id)).then((updatedPlace) => {
        mutate(parsePlaceMetaTypeSections(updatedPlace, placeTypeConfig, allPlaceMetaTypes));
      });
    }, truckMapConfig.placeDetails.submitDebounce);
  };
};

export const isSelectMultiple = (placeMetaType: IPlaceMetaType) => placeMetaType.allowMultiple;

export const useSortedSelectMultipleMetaTypes = (details: PlaceMetaTypeSectionListItem) => {
  const sortedPublishedDetails = details.published?.filter((detail) =>
    isSelectMultiple(detail.placeMetaType)
  );

  const sortedPendingDetails = details.pending?.filter((detail) =>
    isSelectMultiple(detail.placeMetaType)
  );
  const sortedEmptyPlaceMetaTypes = details.empty?.filter((detail) => isSelectMultiple(detail));
  const hasPendingOrPublishedStatus = useCallback(
    (metaObjectKey: string) =>
      !!sortedPublishedDetails.find((detail) => detail.placeMetaType.objectKey === metaObjectKey) ||
      !!sortedPendingDetails.find((detail) => detail.placeMetaType.objectKey === metaObjectKey),
    [sortedPublishedDetails, sortedPendingDetails]
  );
  const sortedPublishedPlaceMetaTypes: IPlaceMetaType[] = useMemo(
    () => sortedPublishedDetails.map((detail) => detail.placeMetaType),
    [details]
  );
  const sortedPendingPlaceMetaTypes: IPlaceMetaType[] = useMemo(
    () => sortedPendingDetails.map((detail) => detail.placeMetaType),
    [details]
  );
  const uniqueMetaTypeObjectKeys = uniqBy(
    sortedPublishedPlaceMetaTypes.concat(sortedPendingPlaceMetaTypes, sortedEmptyPlaceMetaTypes),
    'objectKey'
  ).map((uniqueMetaType) => uniqueMetaType.objectKey);

  return useMemo(() => {
    return {
      sortedPublishedDetails,
      sortedPendingDetails,
      sortedEmptyPlaceMetaTypes,
      hasPendingOrPublishedStatus,
      uniqueMetaTypeObjectKeys
    };
  }, [details]);
};
