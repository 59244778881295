import React, { memo, useCallback, useState } from 'react';

import { DropdownList } from '../../DropdownList';
import { ListOption } from '../../DropdownList/DropdownListOptions';
import { formatHour, hoursOptions, useStyles } from './TimePicker';

const HourPicker = memo(() => {
  const styles = useStyles();
  const [hoursValue, setHoursValue] = useState<ListOption>(null);

  const createHoursOptions = useCallback((): ListOption[] => {
    return hoursOptions.map((item, index) => ({
      value: item,
      customSelected: hoursValue?.value === item,
      buttonLabel: formatHour(item),
      itemLabel: formatHour(item),
      initialValue: index === 0
    }));
  }, [hoursOptions, hoursValue]);

  return (
    <DropdownList
      isSmall
      drawerClassName={styles.drawerClassName}
      options={createHoursOptions()}
      onChange={setHoursValue}
    />
  );
});

HourPicker.displayName = 'HourPicker';

export { HourPicker };
