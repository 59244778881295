'use client';
import React, { Reducer, useEffect, useReducer, useRef } from 'react';

const firstSliderPage = 1;

export type SliderPaginationState = {
  currentPage: number;
  totalPages: number;
};

export type SliderPaginationConfig = Partial<{
  autoSlide: number;
}>;

export enum SliderPaginationActions {
  NEXT,
  PREVIOUS,
  SLIDE_TO
}

export type SliderPaginationActionPayload = Partial<Pick<SliderPaginationState, 'currentPage'>> & {
  type: SliderPaginationActions;
};

export const defaultSliderPaginationState: SliderPaginationState = {
  currentPage: firstSliderPage,
  totalPages: firstSliderPage
};

export const defaultSliderPaginationConfig: SliderPaginationConfig = {
  autoSlide: 0
};

export function sliderPaginationReducer(
  state: SliderPaginationState = defaultSliderPaginationState,
  action?: SliderPaginationActionPayload
): SliderPaginationState {
  switch (action?.type) {
    case SliderPaginationActions.NEXT:
      return {
        ...state,
        currentPage:
          state.currentPage !== state.totalPages ? state.currentPage + 1 : firstSliderPage
      };
    case SliderPaginationActions.PREVIOUS:
      return {
        ...state,
        currentPage:
          state.currentPage !== firstSliderPage ? state.currentPage - 1 : state.totalPages
      };
    case SliderPaginationActions.SLIDE_TO:
      return {
        ...state,
        currentPage:
          action?.currentPage < firstSliderPage || action?.currentPage > state.totalPages
            ? state.currentPage
            : action.currentPage
      };
    default:
      return state;
  }
}

export function useSliderPagination(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[],
  config: SliderPaginationConfig = defaultSliderPaginationConfig
) {
  const sliderPaginationConfig = { ...defaultSliderPaginationConfig, ...config };

  const [state, dispatch] = useReducer<
    Reducer<SliderPaginationState, SliderPaginationActionPayload>
  >(sliderPaginationReducer, {
    ...defaultSliderPaginationState,
    totalPages: items.length
  });

  const slideTimer = useRef<number | NodeJS.Timer>();
  const hasAutoSlide = sliderPaginationConfig.autoSlide > 0;

  const stopAutoSlide = () => {
    if (slideTimer?.current) clearInterval(slideTimer.current as number);
  };

  const startAutoSlide = (timeLimit = sliderPaginationConfig.autoSlide as number) => {
    stopAutoSlide();

    slideTimer.current = setInterval(() => {
      dispatch({ type: SliderPaginationActions.NEXT });
    }, timeLimit);
  };

  if (sliderPaginationConfig.autoSlide > 0) {
    useEffect(() => {
      startAutoSlide();

      return stopAutoSlide;
    }, []);
  }

  return {
    slideNext() {
      dispatch({ type: SliderPaginationActions.NEXT });
      hasAutoSlide && startAutoSlide();
    },
    slidePrevious() {
      dispatch({ type: SliderPaginationActions.PREVIOUS });
      hasAutoSlide && startAutoSlide();
    },
    slideTo(page: number) {
      dispatch({ type: SliderPaginationActions.SLIDE_TO, currentPage: page });
      hasAutoSlide && startAutoSlide();
    },
    startAutoSlide,
    stopAutoSlide,
    items,
    ...state
  };
}

export type SliderPaginationHookProps = ReturnType<typeof useSliderPagination>;

const SliderPaginationContext = React.createContext<SliderPaginationHookProps>(null);

export const SliderPaginationRoot: React.ComponentType<
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[];
  } & SliderPaginationConfig &
    ChildrenProp
> = ({ items, children, ...config }) =>
  React.createElement(
    SliderPaginationContext.Provider,
    { value: useSliderPagination(items, config) },
    children
  );

export const useSliderPaginationContext = () => React.useContext(SliderPaginationContext);
