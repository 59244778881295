'use client';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { getInteraction, IntentType, makeStyles } from 'lib/makeStyles';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useBackgroundVariantStyles } from 'styles/variantStyles/backgroundVariantStyles';
import { useBorderVariantStyles } from 'styles/variantStyles/borderVariantStyles';
import {
  getSecondaryTextHover,
  useTypographyVariantStyles
} from 'styles/variantStyles/typographyVariantStyles';
import { KeyCodes } from 'types/common';

import { InputControlOption, InputControlProps } from './ControlInput';

export type Props = {
  split: boolean;
  intent?: IntentType;
  rounded?: 'left' | 'right' | false;
} & JSX.IntrinsicElements['input'] &
  Pick<InputControlOption, 'hasNodeLink' | 'onClickControlled' | 'checked'> &
  Pick<InputControlProps, 'whiteBackground'>;

type ControlValueStyleProps = Pick<
  Props,
  'split' | 'checked' | 'disabled' | 'intent' | 'rounded'
> & {
  borderStyles?: string;
  backgroundStyles?: string;
  typographyStyles?: string;
};

const useStyles = makeStyles<ControlValueStyleProps>()({
  innerWrapper: (props) => [
    'inline-flex grow',
    'border-t border-b',
    'first-of-type:border-l first-of-type:rounded-l-md',
    'last-of-type:border-r last-of-type:rounded-r-md',
    'w-full h-full',
    'items-center',
    'text-sm',
    'font-medium',
    'focus-default',
    'peer',
    'whitespace-nowrap',
    'select-none',
    props?.borderStyles
  ],
  inputElement: ['sr-only'],
  childrenClass: (props) => [
    'w-full h-full',
    {
      'rounded-l-md': props.rounded === 'left',
      'rounded-r-md': props.rounded === 'right',
      'cursor-pointer': !props.disabled,
      'cursor-auto': props.disabled,
      'font-semibold': props.checked
    },
    props?.backgroundStyles,
    props.typographyStyles
  ],
  splitBorder: (props) => ['group', props.split && props.borderStyles, props.split && 'border-r']
});

type ControlValueWrapperProps = {
  innerWrapper: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: (e?: any) => void;
  isButton?: boolean;
} & Pick<Props, 'onClick'> &
  ChildrenProp;

const ControlValueWrapper: React.FC<ControlValueWrapperProps> = React.memo(
  ({ children, innerWrapper, onKeyDown, isButton = false, onClick }) => {
    return (
      <div
        role={(isButton && 'button') || undefined}
        onClick={onClick}
        className={innerWrapper}
        tabIndex={isButton && 0}
        onKeyDown={isButton ? onKeyDown : undefined}>
        {children}
      </div>
    );
  }
);

ControlValueWrapper.displayName = 'ControlValueWrapper';

const ControlValue: React.FC<Props> = React.memo(
  ({
    children,
    split,
    checked,
    disabled,
    hasNodeLink,
    onClickControlled,
    intent,
    whiteBackground,
    rounded,
    ...inputProps
  }) => {
    const isHoverInteraction = getInteraction({ checked, disabled }) === 'hover';
    const backgroundVariantStyles = useBackgroundVariantStyles({
      enableHover: true,
      whiteBackground
    })({
      intent: whiteBackground && isHoverInteraction ? 'primary' : intent,
      interaction: getInteraction({ checked, disabled }),
      mode: 'default'
    });

    const borderVariantStyles = useBorderVariantStyles({
      enableHover: false,
      // disabled base border style since it uses tailwind first-of-type and last-of-type classes
      baseClassNames: ['']
    })({
      intent,
      interaction: getInteraction({ checked: false, disabled }),
      mode: 'default'
    });

    const typographyVariantStyles = useTypographyVariantStyles('group')({
      intent: 'primary',
      mode: 'default',
      interaction: getInteraction({ checked, disabled })
    });

    const { innerWrapper, inputElement, childrenClass, splitBorder } = useStyles({
      split,
      checked,
      disabled,
      intent,
      rounded,
      borderStyles: borderVariantStyles,
      backgroundStyles: backgroundVariantStyles,
      typographyStyles:
        isHoverInteraction && whiteBackground
          ? `text-primaryText ${getSecondaryTextHover(true)}`
          : typographyVariantStyles
    });
    const inputRef = useRef(null);

    const onKeyDown = (e) => {
      if (e.keyCode !== KeyCodes.ENTER && e.keyCode !== KeyCodes.SPACE) return;
      inputProps.onChange &&
        inputProps.onChange({
          target: { value: inputProps.value }
        } as ChangeEvent<HTMLInputElement>);
    };

    const onClickProxy = useCallback(
      (event) => {
        event?.preventDefault();
        event?.stopPropagation();
        onClickControlled && onClickControlled(inputRef?.current?.value);
      },
      [inputRef?.current]
    );

    const ControlValueInner = React.useMemo(
      () => (
        <div className={splitBorder}>
          {/* to not conflict hover colors with pseudo element */}
          <PanelItem paddingY="XS" paddingX="MD" className={childrenClass}>
            {children}
          </PanelItem>
        </div>
      ),
      [children, splitBorder, childrenClass]
    );

    return (
      <>
        <input
          ref={inputRef}
          type="radio"
          tabIndex={-1}
          checked={checked}
          aria-hidden={true}
          className={inputElement}
          {...inputProps}
        />
        <ControlValueWrapper
          isButton={!hasNodeLink}
          onKeyDown={onKeyDown}
          onClick={onClickProxy}
          innerWrapper={innerWrapper}>
          {ControlValueInner}
        </ControlValueWrapper>
      </>
    );
  }
);

ControlValue.displayName = 'ControlValue';

ControlValue.defaultProps = {
  intent: 'secondary',
  whiteBackground: false
};

export { ControlValue };
