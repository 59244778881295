import { DropdownSelect } from 'components/common/DropdownSelect/DropdownSelect';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { useTranslation } from 'hooks/useContentful';
import { sortHubSpotFormOptions } from 'lib/hubspot/sortHubSpotFormOptions';
import { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const HubSpotDropdownField = memo((field: HubSpotFormFieldType) => {
  const { control } = useFormContext();
  const sortedOptions = useMemo(() => sortHubSpotFormOptions(field.options), [field.options]);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={field.name}
      rules={{ required: field.required }}
      render={({ field: { onChange, value } }) => (
        <DropdownSelect
          options={sortedOptions.map((option) => ({
            value: option.value,
            label: option.label || option.value
          }))}
          defaultLabel={t('SELECT_AN_OPTION')}
          onChange={(option) => onChange(option.value)}
          current={field.options.find((option) => option.value === value)}
        />
      )}
    />
  );
});

HubSpotDropdownField.displayName = 'HubSpotDropdownField';
