import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CheckInIcon = React.memo((props: GlyphProps) => (
  <Glyph width={40} height={40} {...props}>
    <g filter="url(#filter0_d_checkin)">
      <circle cx="20" cy="19" r="16" fill="#48B946" />
    </g>
    <path
      d="M15 20L19 24L25 14"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_checkin"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </Glyph>
));

CheckInIcon.displayName = 'CheckInIcon';

export { CheckInIcon };
