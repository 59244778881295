'use client';
import { Transition } from '@headlessui/react';
import { makeStyles } from 'lib/makeStyles';
import { imageOpacityTransition } from 'lib/transitionProps/transitionProps';
import NextImage, { ImageProps } from 'next/legacy/image';
import { memo, useCallback, useState } from 'react';

type Props = ImageProps & {
  animate?: boolean;
};

const useStyles = makeStyles({
  root: (className: ParentClassNameProp) => ['relative w-full h-full', className]
});

// types are failing on next 11.0.0
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NextImageSrc = NextImage as any;

const Image = memo(({ className, onLoad, animate, src, ...rest }: Props) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleOnLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setHasLoaded(true);
    typeof onLoad === 'function' && onLoad(e);
  }, []);

  const source = (src as string).startsWith('/') ? `https:${src}` : src;

  const transitionClasses = imageOpacityTransition(animate);
  const styles = useStyles({ className });

  return (
    <Transition show={hasLoaded} className={styles.root} {...transitionClasses}>
      <NextImageSrc {...rest} onLoad={handleOnLoad} src={source} />
    </Transition>
  );
});

Image.displayName = 'Image';

export { Image };
