import { Translation } from 'components/common/Translation';
import { BodyText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';

export const HubSpotFormSuccess = memo(() => {
  return (
    <PanelFlex column align="center" justify="center" paddingY="2XL">
      <BodyText as="p" color="success" bold align="center" className="max-w-md">
        <Translation textKey="FORM_SUCCESS_HEADING" />
      </BodyText>
      <BodyText as="p" color="primary" align="center" className="max-w-md">
        <Translation textKey="FORM_SUCCESS_SUBHEADING" />
        <br />
        <br />
        <Translation textKey="THANK_YOU" />.
      </BodyText>
    </PanelFlex>
  );
});

HubSpotFormSuccess.displayName = 'HubSpotFormSuccess';
