import clsx from 'clsx';
import { FeatureBullet } from 'components/contentful/FeatureBullet';
import { useSliderPaginationContext } from 'hooks/useSliderPagination';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful';

const useStyles = makeStyles({
  wrapper:
    'lg:pt-32 lg:pb-5 lg:grid lg:grid-flow-col lg:grid-cols-2 lg:grid-rows-2 lg:gap-x-96 lg:gap-y-20 lg:relative lg:z-20',
  button: 'text-left appearance-none flex items-center py-2 px-1 md:px-4 lg:block',
  buttonAlt: 'lg:bg-white lg:rounded-xl lg:shadow lg:block lg:border lg:border-neutralBorder',
  hidden: 'hidden'
});

const SliderFeatures = React.memo(() => {
  const { currentPage, items, slideTo } = useSliderPaginationContext();
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {(items as SerializedComponentItem[]).map((entry, index) => {
        const pageIndex = index + 1;
        const isCurrentPage = pageIndex === currentPage;
        return (
          <button
            key={`button-${entry.id}`}
            className={clsx(
              styles.button,
              isCurrentPage && styles.buttonAlt,
              !isCurrentPage && styles.hidden
            )}
            onClick={() => !isCurrentPage && slideTo(pageIndex)}>
            <FeatureBullet as="h2" entry={entry} hasBluePrimaryText={isCurrentPage} />
          </button>
        );
      })}
    </div>
  );
});

SliderFeatures.displayName = 'SliderFeatures';

export { SliderFeatures };
