'use client';
import { makeStyles } from 'lib/makeStyles';

import { useContentfulUiComponentFromQuery } from 'hooks/useContentful';
import { memo } from 'react';

const useStyles = makeStyles({
  root: 'bg-white border-t-2 border-dashed border-neutralBorder'
});

export const FooterContainer = memo(({ children }: ChildrenProp) => {
  const styles = useStyles();
  const footerContent = useContentfulUiComponentFromQuery('public-footer');

  // if footer is not present on contentful query, ignore it
  // Example: RoutePlanerPage
  if (!footerContent) {
    return <></>;
  }

  return (
    <footer className={styles.root}>
      <nav>{children}</nav>
    </footer>
  );
});

FooterContainer.displayName = 'FooterContainer';
