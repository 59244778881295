import { MetaText, TypographyOwnProps } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';

export type TranslationType =
  | string
  | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>;

export const InputLabel = memo(
  ({
    label,
    required = false,
    children,
    as = 'label',
    htmlFor,
    hideLabel = false
  }: {
    label: TranslationType;
    required?: boolean;
    hideLabel?: boolean;
    htmlFor: string;
  } & ChildrenProp &
    Pick<TypographyOwnProps, 'as'>) => {
    return (
      <PanelFlex column spacing="SM" fullWidth align="start">
        {!hideLabel && (
          <MetaText as={as} htmlFor={htmlFor}>
            {label}
            {required && <span className="px-1 text-magenta-600">*</span>}
          </MetaText>
        )}
        {children}
      </PanelFlex>
    );
  }
);

InputLabel.displayName = 'InputLabel';
