import { HubSpotFormOption } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';

export const sortHubSpotFormOptions = (options: HubSpotFormOption[]) => {
  return (
    options
      .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
      // Remove displayOrder because it is not read by TruckMap inputs
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ displayOrder, ...rest }) => rest)
  );
};
