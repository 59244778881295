import { atomFamily } from 'recoil';
import { persistanceCacheEffect } from 'recoil/persistanceCacheEffect';

export type PlaceCreateReviewState = {
  reviewId?: string;
  notFilledItems: string[];
  activeStepIndex?: number;
};

export const placeDetailsCreateReviewAtom = atomFamily<PlaceCreateReviewState, string>({
  key: 'placeDetailsCreateReviewAtom',
  default: { notFilledItems: [] },
  effects: [persistanceCacheEffect.persistAtom]
});
