import { Dayjs } from 'dayjs';
import { useCalendar } from 'hooks/useDatePicker';
import { makeStyles } from 'lib/makeStyles';
import React, { memo, useMemo } from 'react';

export type CalendarDateProps = {
  isDateWithinRange?: boolean;
  isDateRangeBound?: boolean;
  isValidDate?: boolean;
  currentDate?: Dayjs;
  selectedDate?: Dayjs;
};

type CalendarDateStyleProps = {
  isSelected?: boolean;
} & CalendarDateProps;

const useStyles = makeStyles<CalendarDateStyleProps>()({
  calendarDate: (props) => [
    'relative',
    'px-[9px] py-1.5',
    'text-sm text-center',
    'rounded box-border border-2 border-transparent',
    {
      'text-secondaryText hover:secondaryTextHover':
        props.isValidDate && !props.isSelected && !props.isDateRangeBound,
      'bg-secondaryBackgroundActive':
        props.isDateWithinRange && !props.isSelected && !props.isDateRangeBound,
      'text-neutralText': !props.isValidDate,
      'cursor-pointer hover:border-tmBlue': props.isValidDate,
      'bg-tmBlue font-semibold text-white': props.isSelected || props.isDateRangeBound,
      'font-normal': !props.isSelected && !props.isDateRangeBound
    }
  ]
});

const CalendarDate = memo((props: CalendarDateProps) => {
  const { isToday } = useCalendar();
  const {
    currentDate,
    selectedDate,
    isDateRangeBound,
    isDateWithinRange,
    isValidDate = true
  } = props;
  const isSelected = useMemo(() => isToday(currentDate, selectedDate), [currentDate, selectedDate]);
  const styles = useStyles({ isValidDate, isSelected, isDateWithinRange, isDateRangeBound });

  return (
    <td>
      <div {...props} className={styles.calendarDate}>
        {currentDate.date()}
      </div>
    </td>
  );
});

CalendarDate.displayName = 'CalendarDate';

export { CalendarDate };
