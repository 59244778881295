import { CarouselProps } from 'components/common/Carousel/Carousel';
import { Asset } from 'components/contentful/Asset/Asset';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { memo } from 'react';

export const CarouselLogos = memo(({ items }: Pick<CarouselProps, 'items'>) => {
  return (
    <PanelFlex spacing="XL" paddingY="2XL" justify="center" fullWidth wrap>
      {items?.map((image) => {
        const imageTitle = image.assets[0]?.title;

        return (
          <Asset
            entry={image}
            key={`CarouselLogos_image_${imageTitle}`}
            className="mx-4 mt-8 mb-4 md:mx-6 opacity-30"
          />
        );
      })}
    </PanelFlex>
  );
});

CarouselLogos.displayName = 'CarouselLogos';
