'use client';
import { Translation } from 'components/common/Translation';
import { useContentfulUiComponentFromQuery } from 'hooks/useContentful';
import { memo } from 'react';

export const FooterSRTitle = memo(() => {
  const footerContent = useContentfulUiComponentFromQuery('public-footer');

  return <h2 className="sr-only">{footerContent?.name ?? <Translation textKey="NAVIGATION" />}</h2>;
});

FooterSRTitle.displayName = 'FooterSRTitle';
