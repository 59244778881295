import { CheckboxInput } from 'components/common/Input/SelectInput';
import { HubSpotFormFieldType } from 'components/tenstreet/HubSpotFormModal/HubSpotFormFields/HubSpotFormFields';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const HubSpotSingleCheckboxField = memo((field: HubSpotFormFieldType) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <CheckboxInput
          name={field.name}
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}>
          <div className="pt-[2px]">{field.label}</div>
        </CheckboxInput>
      )}
    />
  );
});

HubSpotSingleCheckboxField.displayName = 'HubSpotSingleCheckboxField';
