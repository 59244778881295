import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type DateRangePickerState = 'start' | 'end' | false;

/**
 * Handles state for DateRangePicker
 * @returns {
    showDateRangePicker,
    setDateRangePicker,
    startDateValue,
    setStartDateValue,
    endDateValue,
    setEndDateValue,
    isDateWithinRange: (currentDate) => (currentDate),
    isDateRangeBound: (currentDate) => isDateRangeBound(currentDate),
    handleRangeChange: (value) => handleRaisDateWithinRangengeChange(value),
    showEndPicker,
    showStartPicker
  }
 */
export const useDateRangePicker = ({
  defaultStartDateValue,
  defaultEndDateValue
}: {
  defaultStartDateValue?: Dayjs;
  defaultEndDateValue?: Dayjs;
}) => {
  const [showDateRangePicker, setDateRangePicker] = useState<DateRangePickerState>(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(defaultStartDateValue ?? dayjs());
  const [endDateValue, setEndDateValue] = useState<Dayjs>(
    defaultEndDateValue ?? dayjs().add(1, 'day')
  );
  const form = useFormContext();
  const startDatePickerName = 'start_date_picker';
  const endDatePickerName = 'end_date_picker';

  const isDateWithinRange = useCallback(
    (currentDate) => currentDate < endDateValue && currentDate > startDateValue,
    [startDateValue, endDateValue]
  );

  const isDateRangeBound = useCallback(
    (currentDate) => {
      if (showDateRangePicker === 'start') {
        return (
          currentDate.date() === endDateValue?.date() &&
          currentDate?.month() === endDateValue?.month() &&
          currentDate?.year() === endDateValue?.year()
        );
      }

      if (showDateRangePicker === 'end') {
        return (
          currentDate.date() === startDateValue?.date() &&
          currentDate?.month() === startDateValue?.month() &&
          currentDate?.year() === startDateValue?.year()
        );
      }

      return false;
    },
    [showDateRangePicker, startDateValue, endDateValue]
  );

  const handleRangeChange = useCallback(
    (value) => {
      switch (showDateRangePicker) {
        case 'start':
          if (value > endDateValue) {
            setEndDateValue(value);
            form?.setValue(endDatePickerName, value);
          }
          setStartDateValue(value);
          form?.setValue(startDatePickerName, value);
          setDateRangePicker('end');
          break;
        case 'end':
          if (value > startDateValue) {
            setEndDateValue(value);
            form?.setValue(endDatePickerName, value);
          } else {
            setStartDateValue(dayjs(value).subtract(1, 'day'));
            setEndDateValue(value);
            form?.setValue(startDatePickerName, dayjs(value).subtract(1, 'day'));
            form?.setValue(endDatePickerName, value);
          }
      }
    },
    [showDateRangePicker, startDateValue, endDateValue]
  );

  const showEndPicker = useCallback(
    () => (showDateRangePicker === 'end' ? setDateRangePicker(false) : setDateRangePicker('end')),
    [showDateRangePicker, setDateRangePicker]
  );

  const showStartPicker = useCallback(
    () =>
      showDateRangePicker === 'start' ? setDateRangePicker(false) : setDateRangePicker('start'),
    [showDateRangePicker, setDateRangePicker]
  );

  const calendarButtons = useMemo(() => {
    const today = dayjs();
    const tomorrow = dayjs().add(1, 'days');
    const yesterday = dayjs().add(-1, 'days');

    const thisMonthStart = dayjs().startOf('month');
    const thisMonthEnd = dayjs().endOf('month');

    const nextMonthStart = dayjs().add(1, 'month').startOf('month');
    const nextMonthEnd = dayjs().add(1, 'month').endOf('month');

    return {
      today,
      tomorrow,
      yesterday,
      thisMonthStart,
      thisMonthEnd,
      nextMonthStart,
      nextMonthEnd
    };
  }, [dayjs]);

  const handleToday = useCallback(() => {
    setStartDateValue(calendarButtons.today);
    setEndDateValue(calendarButtons.today);
  }, [setStartDateValue, setEndDateValue]);

  const handleTomorrow = useCallback(() => {
    setStartDateValue(calendarButtons.tomorrow);
    setEndDateValue(calendarButtons.tomorrow);
  }, [setStartDateValue, setEndDateValue]);

  const handleThisMonth = useCallback(() => {
    setStartDateValue(calendarButtons.thisMonthStart);
    setEndDateValue(calendarButtons.thisMonthEnd);
  }, [setStartDateValue, setEndDateValue]);

  const handleNextMonth = useCallback(() => {
    setStartDateValue(calendarButtons.nextMonthStart);
    setEndDateValue(calendarButtons.nextMonthEnd);
  }, [setStartDateValue, setEndDateValue]);

  return {
    showDateRangePicker,
    setDateRangePicker,
    startDateValue,
    calendarButtons,
    handlers: {
      today: handleToday,
      tomorrow: handleTomorrow,
      thisMonth: handleThisMonth,
      nextMonth: handleNextMonth
    },
    setStartDateValue,
    endDateValue,
    setEndDateValue,
    isDateWithinRange,
    isDateRangeBound,
    handleRangeChange,
    showEndPicker,
    showStartPicker
  };
};
