import { LoadingSpinnerIcon } from 'icons/monochrome/LoadingSpinnerIcon';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

const useStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => ['flex justify-center items-center', 'w-full h-full', props?.className],
  wrapper: ['p-6']
});

export const LoadingSpinner = memo(
  ({ className, size: fontSize = 32 }: ParentClassNameProp & { size?: number }) => {
    const styles = useStyles({ className });

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <LoadingSpinnerIcon style={{ fontSize }} />
        </div>
      </div>
    );
  }
);

LoadingSpinner.displayName = 'LoadingSpinner';
