import { ContentfulUiComponentItem } from '@truckmap/common';
import { useContentfulUiComponentFromQuery } from 'hooks/useContentful';
import { useMemo } from 'react';

export const useMobileNavigationLinks = (): ContentfulUiComponentItem[] => {
  const publicNavbarContent = useContentfulUiComponentFromQuery('public-navbar');

  const solutionLinks = useMemo(
    () =>
      publicNavbarContent.itemsCollection?.items?.filter((item) =>
        item?.name.includes('Solutions')
      )[0]?.itemsCollection?.items,
    [publicNavbarContent]
  );

  return solutionLinks;
};
