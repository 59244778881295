import { PanelItem } from 'components/layout/Panel/PanelItem';
import { ClockIcon } from 'icons/iconic';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { SmallText } from '../../Typography';

const useStyles = makeStyles({
  clockIcon: 'h-5 w-5 text-primaryIcon',
  panelItem: 'space-x-[2px]'
});

export const TimePickerLabel: React.ComponentType<ChildrenProp> = memo(({ children }) => {
  const styles = useStyles();
  return (
    <PanelItem spacing={false} className={styles.panelItem}>
      <ClockIcon className={styles.clockIcon} />
      <SmallText bold>{children}</SmallText>
    </PanelItem>
  );
});

TimePickerLabel.displayName = 'TimePickerLabel';

export const StartEndLabel = memo((isStart?: boolean) => {
  return <TimePickerLabel>{isStart ? 'Start' : 'End'}</TimePickerLabel>;
});

StartEndLabel.displayName = 'StartEndLabel';
