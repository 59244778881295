type SWRCacheKeys =
  | 'place'
  | 'place_reviews_'
  | 'capacities'
  | 'place_review_vote_summary'
  | 'tracking-full-payload';

export const getCacheKey = (key: SWRCacheKeys, id: string) => `${key}_${id}`;

export const getPlaceDetailKey = (placeId: string) => getCacheKey('place', placeId);
export const getPlaceReviewsKey = (placeId: string) => getCacheKey('place_reviews_', placeId);
export const getPlaceReviewVoteSummaryKey = (placeId: string) =>
  getCacheKey('place_review_vote_summary', placeId);
export const getCapacitiesKey = (freightRequestId: string) =>
  getCacheKey('capacities', freightRequestId);
export const getTrackingFullPayloadKey = (trackingId: string) =>
  getCacheKey('tracking-full-payload', trackingId);
